import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { FolderForm } from './FolderForm';

interface FolderCreateProps {
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const FolderCreate = ({
  trigger,
  isInitialOpen,
  onClosed,
}: FolderCreateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opret mappe"
        closeOnOutsideClick
        hideActionBar
      >
        <FolderForm
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
        />
      </ControlledModal>
    </>
  );
};
