import { MediaSequenceResponse } from 'api/core';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MediaSequenceSetupStep } from './steps';
import { useNavigate } from 'react-router';

interface IMediaSequenceSetupContext {
  steps: MediaSequenceSetupStep[];
  currentStepIndex: number;
  handlePrevious: () => void;
  handleNext: () => void;
  setStep: (step: number) => void;
  currentProgressStepIndex: number;
}

const MediaSequenceSetupContext = createContext<
  IMediaSequenceSetupContext | undefined
>(undefined);

export const MediaSequenceSetupProvider = ({
  mediaSequence,
  initialStepIndex,
  currentProgressStepIndex,
  steps,
  children,
}: {
  mediaSequence: MediaSequenceResponse;
  initialStepIndex: number;
  currentProgressStepIndex: number;
  steps: MediaSequenceSetupStep[];
  children: ReactNode;
}) => {
  const value = useProvideMediaSequenceSetup(
    mediaSequence,
    initialStepIndex,
    currentProgressStepIndex,
    steps
  );
  return (
    <MediaSequenceSetupContext.Provider value={value}>
      {children}
    </MediaSequenceSetupContext.Provider>
  );
};

export const useMediaSequenceSetup = (): IMediaSequenceSetupContext => {
  const context = useContext(MediaSequenceSetupContext);
  if (context === undefined) {
    throw new Error(
      'useMediaSequenceSetup must be used within a MediaSequenceSetupProvider'
    );
  }
  return context;
};

const useProvideMediaSequenceSetup = (
  mediaSequence: MediaSequenceResponse,
  initialStepIndex: number,
  currentProgressStepIndex: number,
  steps: MediaSequenceSetupStep[]
) => {
  const [step, setStep] = useState(initialStepIndex);
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/media-sequences/${mediaSequence.id}/setup/${step}`, {
      replace: true,
    });
  }, [step, navigate, mediaSequence]);

  const handlePrevious = useMemo(() => {
    return () => {
      if (step > 1) {
        setStep(step - 1);
      }
    };
  }, [step]);

  const handleNext = useMemo(() => {
    return () => {
      if (step < steps.length) {
        setStep(step + 1);
      }
    };
  }, [step, steps]);

  return useMemo(
    () => ({
      steps,
      currentStepIndex: step,
      handlePrevious,
      handleNext,
      setStep,
      currentProgressStepIndex,
    }),
    [steps, step, handlePrevious, handleNext, setStep, currentProgressStepIndex]
  );
};
