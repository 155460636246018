/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EAssetSource } from './EAssetSource';
import {
    EAssetSourceFromJSON,
    EAssetSourceFromJSONTyped,
    EAssetSourceToJSON,
} from './EAssetSource';
import type { EAssetType } from './EAssetType';
import {
    EAssetTypeFromJSON,
    EAssetTypeFromJSONTyped,
    EAssetTypeToJSON,
} from './EAssetType';
import type { UserSimpleResponse } from './UserSimpleResponse';
import {
    UserSimpleResponseFromJSON,
    UserSimpleResponseFromJSONTyped,
    UserSimpleResponseToJSON,
} from './UserSimpleResponse';

/**
 * 
 * @export
 * @interface AssetCreateResponse
 */
export interface AssetCreateResponse {
    /**
     * 
     * @type {Date}
     * @memberof AssetCreateResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof AssetCreateResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateResponse
     */
    projectId: string;
    /**
     * 
     * @type {EAssetType}
     * @memberof AssetCreateResponse
     */
    type: EAssetType;
    /**
     * 
     * @type {EAssetSource}
     * @memberof AssetCreateResponse
     */
    source: EAssetSource;
    /**
     * 
     * @type {number}
     * @memberof AssetCreateResponse
     */
    fileSize: number;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateResponse
     */
    originalFileName: string;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateResponse
     */
    presignedUploadUrl: string;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateResponse
     */
    s3FileName: string;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateResponse
     */
    url: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateResponse
     */
    previewUrl: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetCreateResponse
     */
    foundMatchingAsset: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateResponse
     */
    userId?: string | null;
    /**
     * 
     * @type {UserSimpleResponse}
     * @memberof AssetCreateResponse
     */
    user?: UserSimpleResponse;
}

/**
 * Check if a given object implements the AssetCreateResponse interface.
 */
export function instanceOfAssetCreateResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "fileSize" in value;
    isInstance = isInstance && "originalFileName" in value;
    isInstance = isInstance && "presignedUploadUrl" in value;
    isInstance = isInstance && "s3FileName" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "previewUrl" in value;
    isInstance = isInstance && "foundMatchingAsset" in value;

    return isInstance;
}

export function AssetCreateResponseFromJSON(json: any): AssetCreateResponse {
    return AssetCreateResponseFromJSONTyped(json, false);
}

export function AssetCreateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetCreateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'projectId': json['projectId'],
        'type': EAssetTypeFromJSON(json['type']),
        'source': EAssetSourceFromJSON(json['source']),
        'fileSize': json['fileSize'],
        'originalFileName': json['originalFileName'],
        'presignedUploadUrl': json['presignedUploadUrl'],
        's3FileName': json['s3FileName'],
        'url': json['url'],
        'previewUrl': json['previewUrl'],
        'foundMatchingAsset': json['foundMatchingAsset'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserSimpleResponseFromJSON(json['user']),
    };
}

export function AssetCreateResponseToJSON(value?: AssetCreateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'projectId': value.projectId,
        'type': EAssetTypeToJSON(value.type),
        'source': EAssetSourceToJSON(value.source),
        'fileSize': value.fileSize,
        'originalFileName': value.originalFileName,
        'presignedUploadUrl': value.presignedUploadUrl,
        's3FileName': value.s3FileName,
        'url': value.url,
        'previewUrl': value.previewUrl,
        'foundMatchingAsset': value.foundMatchingAsset,
        'userId': value.userId,
        'user': UserSimpleResponseToJSON(value.user),
    };
}

