import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useGetMediaSequences } from 'api/useMediaSequencesApi';
import { MediaSequencesTable } from './components/MediaSequence/MediaSequencesTable';
import { MediaSequenceCreate } from './components/MediaSequence/MediaSequenceCreate';
import { useIsMobile } from 'utils/useIsMobile';
import { useAuth } from 'auth/AuthProvider';
import { EPermission } from 'api/core';
import { useParams } from 'react-router';
import { useGetFolder } from 'api/useFoldersApi';

export const MediaSequenceFolder = () => {
  const { id } = useParams<{ id: string }>();
  const isMobile = useIsMobile();
  const { state } = useTableQueryState();
  const { hasPermissions } = useAuth();

  const { data: folder } = useGetFolder(id);

  const { data, isFetching: isFetchingFields } = useGetMediaSequences(
    {
      refetchInterval: isMobile ? undefined : 5000,
    },
    state,
    id
  );

  if (!folder) return null;

  return (
    <Page
      title="Smart Video - Mappenavn"
      breadcrumbs={[
        { name: 'Smart Video' },
        { name: 'Mapper' },
        { name: folder.name },
      ]}
    >
      <div className="flex justify-start py-2 pb-3 w-full">
        <MediaSequenceCreate
          folderId={folder.id}
          trigger={
            <button className="btn btn-primary">
              Opret video i {folder.name}
            </button>
          }
        />
      </div>
      <MediaSequencesTable
        data={data}
        isFetching={isFetchingFields}
        queryState={state}
        showType={hasPermissions([
          EPermission.MediaSequenceGlobalTemplateAdministrator,
        ])}
        showFolder={false}
      />
    </Page>
  );
};
