import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useGetTickets } from 'api/useServiceTicketApi';
import { ServiceTicketsTable } from 'pages/Organization/ServiceTicketsTable';

export const ServiceTicketAdminView = () => {
  const { state } = useTableQueryState();

  if (state.sorting.length === 0) {
    state.setSorting([{ id: 'lastModifiedUtc', desc: true }]);
  }

  const { data: tickets, isFetching: isFetching } = useGetTickets(true, state);

  return (
    <Page
      title={'Alle supportsager'}
      breadcrumbs={[{ name: 'Administration' }, { name: 'Alle supportsager' }]}
    >
      <ServiceTicketsTable
        data={tickets}
        isFetching={isFetching}
        queryState={state}
        showOrganizationColumn={true}
        showSupportersColumn={true}
      />
    </Page>
  );
};
