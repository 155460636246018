/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ETranslationTermNamespace = {
    CoreFrontend: 'CoreFrontend',
    CoreBackend: 'CoreBackend',
    EstateOrbitFrontend: 'EstateOrbitFrontend',
    CoreField: 'CoreField',
    Unknown: 'Unknown'
} as const;
export type ETranslationTermNamespace = typeof ETranslationTermNamespace[keyof typeof ETranslationTermNamespace];


export function ETranslationTermNamespaceFromJSON(json: any): ETranslationTermNamespace {
    return ETranslationTermNamespaceFromJSONTyped(json, false);
}

export function ETranslationTermNamespaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ETranslationTermNamespace {
    return json as ETranslationTermNamespace;
}

export function ETranslationTermNamespaceToJSON(value?: ETranslationTermNamespace | null): any {
    return value as any;
}

