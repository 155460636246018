import { Step } from 'react-joyride';

export const DASHBOARD_GUIDE_STEPS: Step[] = [
  {
    target: '#sidebar-menu',
    title: 'Menu',
    content:
      'Den sidemenu giver dig adgang til alle funktioner i Smart Create. Klik på ikonerne for at navigere rundt.',
    disableBeacon: true,
  },
  {
    target: '#header-change-organization',
    title: 'Butik skift',
    content: 'Her kan du skifte mellem de butikker du har adgang til.',
    disableBeacon: true,
  },
  {
    target: '#header-user-menu',
    title: 'Bruger menu',
    content: 'Denne menu giver dig adgang til nogle indstillinger og log ud.',
    disableBeacon: true,
  },
  {
    target: '#sidebar-home',
    title: 'Hjem',
    content:
      'Her finder du dit dashboard som giver dig et hurtigt overblik over din forretning.',
    disableBeacon: true,
  },
  {
    target: '#sidebar-projects',
    title: 'Projekter',
    content:
      'Her finder du alle dine projekter. Du kan oprette, redigere og slette projekter.',
    disableBeacon: true,
  },
  {
    target: '#sidebar-media-sequence',
    title: 'Smart Video',
    content:
      'Her finder du alle dine videoer. Du kan oprette, redigere og slette videoer.',
    disableBeacon: true,
  },
  {
    target: '#sidebar-smart-text',
    title: 'Smart Text',
    content:
      'Her finder du alle dine tekster. Du kan oprette, redigere og slette tekster.',
    disableBeacon: true,
  },
  {
    target: '#sidebar-publications',
    title: 'Smart Planner',
    content:
      'Her finder du alle dine planlægninger. Du kan oprette, redigere og slette planlægninger.',
    disableBeacon: true,
  },
  {
    target: '#sidebar-organization',
    title: 'Butik',
    content: 'Her finder du din butik. Du kan styre brugere, filer og mere.',
    disableBeacon: true,
  },
];
