import { CircleCheckBigIcon } from 'lucide-react';
import { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface AccordionProps {
  title: ReactNode;
  children: ReactNode;
  customTitleIcon?: ReactNode;
  isSuccess?: boolean;
}

export const Accordion = ({
  title,
  children,
  customTitleIcon,
  isSuccess,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={twMerge(
        'collapse collapse-arrow bg-base-200 rounded-none',
        isOpen && 'collapse-open'
      )}
    >
      <input
        className="cursor-pointer"
        type="checkbox"
        checked={isOpen}
        onChange={() => setIsOpen((prev) => !prev)}
      />
      <div className="collapse-title flex justify-between items-center">
        <span className="font-bold">{title}</span>
        {customTitleIcon}
        {isSuccess ? (
          <CircleCheckBigIcon className="h-5 w-5 text-success" />
        ) : null}
      </div>
      <div className="collapse-content">{children}</div>
    </div>
  );
};
