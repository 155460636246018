import { useGetUsers, useResendWelcomeMail } from 'api/useUsersApi';
import { useTableQueryState } from 'components/Table/useTableQueryState';
import { UserCreate } from 'pages/Administration/components/User/UserCreate';
import { UsersTable } from 'pages/Administration/components/User/UsersTable';
import { Page } from 'layouts/Admin/Page';
import { useAuth } from 'auth/AuthProvider';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission } from 'api/core';
import { ORGANIZATION_USERS_GUIDE_STEPS } from './organization-guide-steps';

export const OrganizationUsers = () => {
  const { user } = useAuth();
  const { state } = useTableQueryState();
  const { data: users, isFetching } = useGetUsers(state);

  const { mutateAsync: resendWelcomeMailAsync } = useResendWelcomeMail();

  const resendWelcomeEmail = async () => {
    if (!users?.data.length) return;
    const confirmMessage = `Er du sikker på at du vil gensende velkomstmail til ${users.data.length} brugere?`;

    if (!confirm(confirmMessage)) return;

    for (const user of users.data) {
      await resendWelcomeMailAsync({ id: user.id });
    }
  };

  return (
    <Page
      id="users-overview"
      title={user?.organization.name}
      breadcrumbs={[
        { name: user?.organization.name ?? '' },
        { name: 'Brugere' },
      ]}
      guideSteps={ORGANIZATION_USERS_GUIDE_STEPS}
    >
      <div id="users-overview" className="flex">
        <PermissionProtectedComponent permissions={[EPermission.UserWrite]}>
          <UserCreate
            trigger={
              <div
                id="users-add-user"
                className="flex justify-start py-4 w-full"
              >
                <button className="btn btn-primary mr-2">Opret bruger</button>
              </div>
            }
          />
        </PermissionProtectedComponent>
        <PermissionProtectedComponent
          permissions={[
            EPermission.UserWrite,
            EPermission.UserResendWelcomeMail,
          ]}
        >
          <div>
            <div className="flex justify-start py-4 w-full">
              <button
                className="btn btn-primary mr-2"
                onClick={resendWelcomeEmail}
                disabled={isFetching || !users?.data.length}
              >
                Send velkomstmail til {users?.data.length} brugere
              </button>
            </div>
          </div>
        </PermissionProtectedComponent>
      </div>
      <UsersTable
        data={users}
        isFetching={isFetching}
        queryState={state}
        adminView={false}
      />
    </Page>
  );
};
