/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FolderResponse } from './FolderResponse';
import {
    FolderResponseFromJSON,
    FolderResponseFromJSONTyped,
    FolderResponseToJSON,
} from './FolderResponse';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';

/**
 * 
 * @export
 * @interface FolderResponsePagedData
 */
export interface FolderResponsePagedData {
    /**
     * 
     * @type {Page}
     * @memberof FolderResponsePagedData
     */
    page: Page;
    /**
     * 
     * @type {Array<FolderResponse>}
     * @memberof FolderResponsePagedData
     */
    data: Array<FolderResponse>;
}

/**
 * Check if a given object implements the FolderResponsePagedData interface.
 */
export function instanceOfFolderResponsePagedData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function FolderResponsePagedDataFromJSON(json: any): FolderResponsePagedData {
    return FolderResponsePagedDataFromJSONTyped(json, false);
}

export function FolderResponsePagedDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FolderResponsePagedData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': PageFromJSON(json['page']),
        'data': ((json['data'] as Array<any>).map(FolderResponseFromJSON)),
    };
}

export function FolderResponsePagedDataToJSON(value?: FolderResponsePagedData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': PageToJSON(value.page),
        'data': ((value.data as Array<any>).map(FolderResponseToJSON)),
    };
}

