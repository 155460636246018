import {
  browserTracingIntegration,
  init,
  reactRouterV7BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

process.env.REACT_APP_ENVIRONMENT !== 'development' &&
  init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: 'https://b7813cf32e537209e2e5e2841c3526d9@o543544.ingest.us.sentry.io/4508267175084032',
    integrations: [
      reactRouterV7BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation: useLocation,
        useNavigationType: useNavigationType,
        createRoutesFromChildren: createRoutesFromChildren,
        matchRoutes: matchRoutes,
      }),
      browserTracingIntegration(),
      replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/staging-api.smartcreate\.dk/,
      /^https:\/\/api.smartcreate\.dk/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
