import { ResetPasswordUsingKeyRequest } from 'api/core';
import { useGetThemeForResetPage, useResetPassword } from 'api/useAuthApi';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

export const RegisterView = () => {
  const { passwordKey } = useParams<{ passwordKey: string }>();
  const { mutateAsync, isPending } = useResetPassword();
  const { data: theme, isError: themeFailed } =
    useGetThemeForResetPage(passwordKey);
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm<ResetPasswordUsingKeyRequest>({
    defaultValues: {
      resetKey: passwordKey,
    },
  });

  if (themeFailed) {
    toast.error('Nulstillingsnøglen er ugyldig eller udløbet');
    console.warn('Failed to get theme for reset page');
    navigate('/login', { replace: true });
    return;
  }

  if (!theme) return; // Will never happen, since themeFailed is false at this point

  if (!theme.isNewUser) {
    toast.error('Du har allerede indstillet din adgangskode');
    navigate('/login', { replace: true });
    return;
  }

  document
    .querySelector('html')
    ?.setAttribute('data-theme', theme.theme || 'default');

  const onSubmit = handleSubmit(async (result) => {
    try {
      await mutateAsync({
        resetPasswordUsingKeyRequest: result,
        isRegisteringer: true,
      });
    } catch {
      /* empty */
    }
  });

  return (
    <>
      <div className="divider"></div>
      <div className="text-center flex flex-col gap-2">
        <p>Velkommen {theme.name}</p>
        <p>Du er blevet invitieret til {theme.organizationName}.</p>
        <p>Angiv en adgangskode for at færdiggøre oprettelsen.</p>
      </div>
      <form onSubmit={onSubmit} className="space-y-4">
        <div className="form-control">
          <input
            {...register('resetKey', { required: true })}
            type="text"
            className="hidden"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="text-base label-text">Adgangskode</span>
          </label>
          <input
            {...register('password', { required: true })}
            type="password"
            placeholder="Adgangskode"
            className="w-full input input-bordered"
            minLength={8}
          />
        </div>

        <div>
          <button className="btn btn-primary btn-block" disabled={isPending}>
            Færddiggør oprettelse
          </button>
        </div>
      </form>
    </>
  );
};
