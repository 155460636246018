import { useGetFolders } from 'api/useFoldersApi';
import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useAuth } from 'auth/AuthProvider';
import { FoldersTable } from 'pages/Administration/components/Folder/FoldersTable';
import { EPermission } from 'api/core';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { FolderCreate } from 'pages/Administration/components/Folder/FolderCreate';

export const MediaSequenceFolders = () => {
  const { user } = useAuth();
  const { state } = useTableQueryState();
  const { data: assets, isFetching } = useGetFolders(state);

  if (!user) return null;

  return (
    <Page
      title="Smart Video - Mapper"
      breadcrumbs={[{ name: 'Smart Video' }, { name: 'Mapper' }]}
    >
      <PermissionProtectedComponent permissions={[EPermission.FolderWrite]}>
        <FolderCreate
          trigger={
            <div className="flex justify-start py-4 w-full">
              <button className="btn btn-primary mr-2">Opret mappe</button>
            </div>
          }
        />
      </PermissionProtectedComponent>

      <FoldersTable
        linkPath="/media-sequences/folders"
        data={assets}
        isFetching={isFetching}
        queryState={state}
      />
    </Page>
  );
};
