import { ProjectUpdateRequest } from 'api/core';
import { useGetProject, useUpdateProject } from 'api/useProjectsApi';
import { Page } from 'layouts/Admin/Page';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

enum ECategory {
  General = 'general',
  Table = 'table',
  Case = 'case',
}

interface EstateOrbitOverridable {
  name: string;
  variable: string;
  type: 'oklch' | 'color';
  category: ECategory;
}

interface VariableValue {
  variable: string;
  value: string;
}

const translateOverridableType = (type: EstateOrbitOverridable['type']) => {
  switch (type) {
    case 'color':
      return 'Farve';
    case 'oklch':
      return 'Oklch';
    default:
      return 'Ukendt';
  }
};

const translateOverridableCategory = (type: ECategory) => {
  switch (type) {
    case ECategory.Table:
      return 'Tabel';
    case ECategory.Case:
      return 'Bolig';
    case ECategory.General:
      return 'Generelt';
    default:
      return 'Ukendt';
  }
};

const overridableVariables: EstateOrbitOverridable[] = [
  {
    category: ECategory.General,
    name: 'Primærfarve',
    variable: '--p',
    type: 'oklch',
  },
  {
    category: ECategory.Table,
    name: 'Aktiv række',
    variable: '--table-row-active',
    type: 'color',
  },
  {
    category: ECategory.Table,
    name: 'Lige række',
    variable: '--table-row-even',
    type: 'color',
  },
  {
    category: ECategory.Table,
    name: 'Tabelhoved',
    variable: '--table-head',
    type: 'color',
  },
  {
    category: ECategory.Case,
    name: 'Ledig bolig',
    variable: '--case-fill-available',
    type: 'color',
  },
  {
    category: ECategory.Case,
    name: 'Reserveret bolig',
    variable: '--case-fill-reserved',
    type: 'color',
  },
  {
    category: ECategory.Case,
    name: 'Ej ledig bolig',
    variable: '--case-fill-unavailable',
    type: 'color',
  },
  {
    category: ECategory.Case,
    name: 'Ukendt bolig',
    variable: '--case-fill-indicator',
    type: 'color',
  },
];

const availableCategories = Object.values(ECategory);

export const ProjectStyling = () => {
  const { id } = useParams<{ id: string }>();

  const { data: project } = useGetProject(id, true);
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateProject();

  const [variableValues, setVariableValues] = useState<VariableValue[]>(
    overridableVariables.map((variable) => ({
      variable: variable.variable,
      value: '',
    }))
  );

  useEffect(() => {
    const css = project?.estateOrbitStyling || '';
    const variables = (css.match(/--([^:]+):([^;]+);/g) || []).map((match) => ({
      variable: match.split(':')[0].trim(),
      value: match.split(':')[1].replace(';', '').trim(),
    }));
    setVariableValues((prev) => {
      return prev.map((variable) => {
        const match = variables.find((v) => v.variable === variable.variable);
        if (match) {
          return match;
        }
        return variable;
      });
    });
  }, [project]);

  const { handleSubmit } = useForm({});

  const onSubmit = handleSubmit(async () => {
    if (!project) return;

    const result: ProjectUpdateRequest = {
      estateOrbitCustomButtonScript: project.estateOrbitCustomButtonScript,
      estateOrbitEnabled: project.estateOrbitEnabled,
      estateOrbitStyling: '',
      estateOrbitViewMode: project.estateOrbitViewMode,
      estateOrbitRenderingMode: project.estateOrbitRenderingMode,
      languageIds: project.languages.map((language) => language.id),
      name: project.name,
    };

    result.estateOrbitStyling = ':root {\n';
    variableValues.forEach((variable) => {
      if (variable.value) {
        result.estateOrbitStyling += `  ${variable.variable}: ${variable.value};\n`;
      }
    });
    result.estateOrbitStyling += '}';

    await updateAsync({
      id: project.id,
      projectUpdateRequest: result,
    });
  });

  return (
    <Page
      title={project?.name}
      breadcrumbs={[
        { name: 'Projekter' },
        { name: project?.name ?? '' },
        { name: 'Boligvælger' },
        { name: 'Udseende' },
      ]}
      description="Tilpas udseendet af boligvælgeren. Disse ændringer indkluderes automatisk i boligvælgeren."
    >
      <div className="flex space-x-4 items-stretch">
        {/* Table Container */}
        <div className="w-2/3 flex flex-col">
          <form onSubmit={onSubmit} className="space-y-4 mt-4">
            <div className="overflow-x-auto flex-1 space-y-4">
              {availableCategories.map((category) => {
                const variablesInCategory = overridableVariables.filter(
                  (variable) => variable.category === category
                );
                return (
                  <div className="card border shadow-sm" key={category}>
                    <div className="card-body p-4">
                      <h2 className="card-title">
                        {translateOverridableCategory(category)}
                      </h2>
                    </div>
                    <table className="table h-full">
                      <thead>
                        <tr>
                          <th style={{ width: '25%' }}>Navn</th>
                          <th style={{ width: '20%' }}>Type</th>
                          <th style={{ width: '55%' }}>Værdi</th>
                        </tr>
                      </thead>
                      <tbody>
                        {variablesInCategory.map((variable) => (
                          <tr key={variable.variable}>
                            <td>{variable.name}</td>
                            <td>{translateOverridableType(variable.type)}</td>
                            <td>
                              <input
                                type="text"
                                className="input input-bordered"
                                value={
                                  variableValues.find(
                                    (v) => v.variable === variable.variable
                                  )?.value
                                }
                                onChange={(e) => {
                                  const newVariableValues = [...variableValues];
                                  const index = newVariableValues.findIndex(
                                    (v) => v.variable === variable.variable
                                  );
                                  newVariableValues[index] = {
                                    ...newVariableValues[index],
                                    value: e.target.value,
                                  };
                                  setVariableValues(newVariableValues);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </div>
            <button
              className="btn btn-primary w-fit"
              type="submit"
              disabled={isPendingUpdate}
            >
              Gem ændringer
            </button>
          </form>
        </div>

        {/* Code Box Container */}
        <div className="w-1/3 flex flex-col">
          <div className="mockup-code text-primary flex-1 h-full">
            <pre className="px-6 select-all h-full">
              <code>
                :root {'{'}
                {variableValues
                  .filter((variable) => variable.value)
                  .map((variable) => (
                    <div key={variable.variable}>
                      &nbsp;&nbsp;{variable.variable}: {variable.value};
                    </div>
                  ))}
                {'}'}
              </code>
            </pre>
          </div>
        </div>
      </div>
    </Page>
  );
};
