import { EPermission } from 'api/core';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { NavLink } from 'react-router';

interface SidebarProps {
  setIsSidebarOpen: (open: boolean) => void;
}

export const MobileNavigation = ({ setIsSidebarOpen }: SidebarProps) => {
  const onNavigate = () => {
    if (window.innerWidth < 640) {
      setIsSidebarOpen(false);
    }
  };

  return (
    <div className="btm-nav h-20 z-10 bg-base-200 md:hidden">
      <MobileNavigationItem
        title="Oversigt"
        icon={<AnimatedIcon icon="home-icon" className="w-8 h-8" />}
        to=""
        onNavigate={onNavigate}
      />
      <MobileNavigationItem
        title="Filer"
        icon={<AnimatedIcon icon="asset-icon" className="w-8 h-8" />}
        to="organization/assets"
        onNavigate={onNavigate}
      />
    </div>
  );
};

interface MobileNavigationItemProps {
  title: React.ReactNode;
  icon: JSX.Element;
  permissions?: EPermission[];
  to: string;
  onNavigate?: () => void;
}

export const MobileNavigationItem = ({
  title,
  icon,
  permissions,
  to,
  onNavigate,
}: MobileNavigationItemProps) => {
  return (
    <PermissionProtectedComponent permissions={permissions}>
      <NavLink className="bg-base-200" to={to} onClick={onNavigate}>
        {icon}
        <span className="btm-nav-label text-base-content">{title}</span>
      </NavLink>
    </PermissionProtectedComponent>
  );
};
