import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { Tooltip } from 'components/Tooltip';
import { Link } from 'components/Typography/Link';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface LinkCellProps {
  path: string;
  icon?: ReactNode;
  text?: string;
  hideIcon?: boolean;
  tooltip?: string;
  isExternal?: boolean;
  disabled?: boolean;
}

export const LinkCell = ({
  path,
  icon,
  text,
  hideIcon,
  tooltip,
  isExternal,
  disabled,
}: LinkCellProps) => {
  return (
    <Tooltip tooltip={tooltip}>
      <Link
        to={path}
        target={isExternal ? '_blank' : undefined}
        className="flex items-center gap-x-2"
      >
        {!hideIcon ? (
          <button
            className="text-primary-content flex flex-col items-center justify-center h-6 w-6"
            disabled={disabled}
          >
            {icon ? (
              icon
            ) : (
              <AnimatedIcon
                icon="open-icon"
                disablePlayOnHover={disabled}
                className={twMerge(
                  'h-6 w-6 flex-shrink-0',
                  disabled && 'text-primary-content opacity-20'
                )}
              />
            )}
          </button>
        ) : null}
        {text ? (
          <span className="text-primary-content leading-none">{text}</span>
        ) : null}
      </Link>
    </Tooltip>
  );
};
