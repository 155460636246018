import { Page } from 'layouts/Admin/Page';
import {
  BRANCH_TEMPLATE_FORMATS,
  TemplateFormat,
} from './components/MediaSequenceBranch/BranchTemplates';
import { useState } from 'react';
import { formatStringArray } from 'utils/format-string-array';
import { twMerge } from 'tailwind-merge';
import { Accordion } from 'components/Accordion/Accordion';
import { useAuth } from 'auth/AuthProvider';

export const MediaSequenceRecordingGuide = () => {
  return (
    <Page
      title="Smart Video - Optagelsesguide"
      breadcrumbs={[{ name: 'Smart Video' }, { name: 'Optagelsesguide' }]}
    >
      <div className="flex flex-col gap-2">
        <h1 className="font-bold">Få det bedste ud af Smart Video</h1>
        <p>Denne guide hjælper dig med at få mest muligt ud af Smart Video.</p>

        <Accordion title="Forberedelse">
          <div className="flex flex-col gap-2">
            <p>
              Forbered dig selv og dine omgivelser, før du begynder at optage.
            </p>
            <ul className="list-disc ps-6">
              <li>Vælg et roligt sted uden baggrundsstøj</li>
              <li>Brug et godt oplyst område</li>
              <li>Brug en god mikrofon</li>
              <li>Optag flere korte klip i stedet for få lange</li>
            </ul>
          </div>
        </Accordion>

        <Accordion title="iPhone anbefalinger">
          <div className="flex flex-col gap-2">
            <p>Sådan får du det bedste resultat med iPhone-video.</p>
            <ul className="list-disc ps-6">
              <li>
                Slå HDR fra:
                <ul className="list-disc ps-6">
                  <li>
                    Gå til <b>Indstillinger</b> på din iPhone
                  </li>
                  <li>
                    Vælg <b>Kamera</b>
                  </li>
                  <li>
                    Tryk på <b>Optag video</b>
                  </li>
                  <li>
                    Slå <b>HDR-video</b> fra
                  </li>
                </ul>
              </li>
              <li>Optag i 60 FPS for optimal kvalitet</li>
            </ul>
          </div>
        </Accordion>

        <Accordion title="Android anbefalinger">
          <div className="flex flex-col gap-2">
            <p>Sådan får du det bedste resultat med Android-video.</p>
            <ul className="list-disc ps-6">
              <li>
                Slå HDR fra:
                <ul className="list-disc ps-6">
                  <li>
                    Åbn <b>Kamera</b>-appen på din Android-enhed
                  </li>
                  <li>
                    Gå til <b>Indstillinger</b> (typisk et tandhjulsikon)
                  </li>
                  <li>
                    Find indstillingen for <b>HDR</b> eller{' '}
                    <b>High Dynamic Range</b>
                  </li>
                  <li>Slå HDR fra</li>
                </ul>
              </li>
              <li>Optag i 60 FPS for optimal kvalitet</li>
            </ul>
          </div>
        </Accordion>

        <Accordion title="Optagelse">
          <OutputSelector />
        </Accordion>
      </div>
    </Page>
  );
};

const OutputSelector = () => {
  const { user } = useAuth();

  const [selectedFormat, setSelectedFormat] = useState<TemplateFormat>(
    BRANCH_TEMPLATE_FORMATS[0]
  );

  return (
    <section>
      <div className="flex flex-col gap-2">
        <p>
          Når du ved, hvilke medier din video skal udgives på, kan du tilpasse
          optagelsen derefter. Vælg et medie nedenfor for at få optagelsestips
          til netop det format.
        </p>
        <p>
          Optag i <b>landskab</b>, hvis videoen skal bruges både i{' '}
          <b>landskab</b> og <b>portræt</b> - det giver bedre
          beskæringsmuligheder.
        </p>
      </div>

      <div className="divider"></div>

      <div className="flex flex-col md:flex-row gap-6 mt-6">
        <div className="flex w-full md:w-1/3 flex-col gap-4">
          {BRANCH_TEMPLATE_FORMATS.map((format, index) => (
            <div key={`${index}-${format.name}`} className="group">
              <button
                onClick={() => setSelectedFormat(format)}
                disabled={selectedFormat === format}
                className="w-full btn btn-primary"
              >
                Optag til {format.name}
              </button>
              <p className="text-gray-500 text-sm mt-1 opacity-80 group-hover:opacity-100 transition-opacity">
                Anbefalet til {formatStringArray(format.recommendedUses)}
              </p>
            </div>
          ))}
        </div>

        <div className="flex w-full md:w-2/3 flex-col md:flex-row gap-8">
          <div className="flex flex-col w-full md:w-1/2 gap-2">
            <h2 className="text-lg font-bold">{selectedFormat.name}</h2>
            <p className="text-sm text-gray-600">
              Anbefalet til: {formatStringArray(selectedFormat.recommendedUses)}
            </p>

            <p>{selectedFormat.guideText}</p>

            <p>
              Telefonillustrationen viser, hvordan du skal holde din telefon
              under optagelsen.
            </p>
          </div>

          <div className="flex w-full md:w-1/2 justify-center items-center">
            <div
              className={twMerge(
                'mockup-phone transition-transform duration-500 p-1',
                selectedFormat.portraitRecommended ? 'rotate-0' : '-rotate-90'
              )}
            >
              <div className="camera !w-[60px]"></div>
              <div className="display">
                <div className="artboard artboard-demo w-[160px] h-[300px]">
                  <img
                    src={user?.organization?.logoUrl ?? '/logo-icon.svg'}
                    className="w-28"
                    alt="Smart Create"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
