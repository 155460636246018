import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
// TODO: Enable i18n in the future
// import './i18n';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';
import { useIsMobile } from 'utils/useIsMobile';
import { queryClient } from 'query-client';

process.env.REACT_APP_GOOGLE_ANALYTICS_ID &&
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

interface AppProps {
  children: ReactNode;
}

export const App = ({ children }: AppProps) => {
  const isMobile = useIsMobile();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      {children}
      <ToastContainer limit={isMobile ? 1 : 2} />
    </QueryClientProvider>
  );
};
