import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useAuth } from './AuthProvider';
import { EPermission } from 'api/core';
import { toast } from 'react-toastify';

interface ProtectedRouteProps {
  permissions?: EPermission[];
  children: ReactNode;
}

export const PermissionProtectedRoute = ({
  permissions,
  children,
}: ProtectedRouteProps) => {
  const { hasPermissions } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasPermissions(permissions)) {
      toast.error(
        'Du har ikke tilladelse til at se denne side og er blevet sendt tilbage til forsiden'
      );
      navigate('/');
    }
  }, [hasPermissions, permissions, navigate]);

  return <>{children}</>;
};
