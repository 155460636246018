import { Tooltip } from 'components/Tooltip';
import { formatDistanceToNow, formatTimestamp } from 'utils/format/date';

interface DateTimeCellProps {
  id?: string;
  value: Date;
}

export const DateTimeCell = ({ id, value }: DateTimeCellProps) => {
  return (
    <Tooltip tooltip={formatDistanceToNow(value)}>
      <span id={id}>{formatTimestamp(value)}</span>
    </Tooltip>
  );
};
