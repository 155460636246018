import {
  EMediaSequenceState,
  EMediaSequenceType,
  EPermission,
  ETranscriptionState,
  MediaSequenceResponse,
} from 'api/core';
import { PageAlert } from 'layouts/Admin/Page';
import { ReactNode } from 'react';
import { MediaSequenceSetupAssets } from './MediaSequenceSetupAssets';
import { MediaSequenceSetupBranchMutations } from './MediaSequenceSetupBranchMutations';
import { MediaSequenceSetupBranches } from './MediaSequenceSetupBranches';
import { MediaSequenceSetupResult } from './MediaSequenceSetupResult';
import { MediaSequenceSetupTranscription } from './MediaSequenceSetupTranscription';
import { MediaSequenceSetupError } from './MediaSequenceSetupError';
import { MediaSequenceSetupSound } from './MediaSequenceSetupSound';
import { MediaSequenceSetupTemplate } from './MediaSequenceSetupTemplate';
import { isTemplate } from 'pages/MediaOrbit/media-sequence-utils';

export interface MediaSequenceSetupStep {
  id: string;
  title: string;
  pageAlert?: PageAlert;
  component: ReactNode;
  disablePrevious?: boolean;
  disableNext?: boolean;
  permissions: EPermission[];
}

export const getSetupSteps = (
  mediaSequence?: MediaSequenceResponse
): MediaSequenceSetupStep[] => {
  if (!mediaSequence) return [];

  const disabled = mediaSequence?.state !== EMediaSequenceState.Draft;

  const result: MediaSequenceSetupStep[] = [
    ...(mediaSequence.showTemplateStep
      ? [
          {
            id: 'template',
            title: 'Skabelon',
            pageAlert: {
              header: 'Opsætning af skabelon',
              message:
                'Her skal du udfylde værdier som er påkrævet i skabelonen.',
            },
            component: (
              <MediaSequenceSetupTemplate
                mediaSequence={mediaSequence}
                disabled={disabled}
              />
            ),
            permissions: [EPermission.MediaSequenceEditorAssets],
          } as MediaSequenceSetupStep,
        ]
      : []),
    {
      id: 'assets',
      title: 'Filer',
      pageAlert: {
        header: 'Opsætning af videofiler',
        message:
          'Her kan du tilføje billeder og videoklip, tildele dem effekter og ændre rækkefølgen i din video.',
      },
      component: (
        <MediaSequenceSetupAssets
          mediaSequence={mediaSequence}
          disabled={disabled}
        />
      ),
      permissions: [EPermission.MediaSequenceEditorAssets],
    },
    {
      id: 'branches',
      title: 'Formater',
      pageAlert: {
        header: 'Opsætning af videoformater',
        message:
          'Vælg de formater som din video skal udgives i. Smart Video vil automatisk tilpasse videoen.',
      },
      component: (
        <MediaSequenceSetupBranches
          mediaSequence={mediaSequence}
          disabled={disabled}
        />
      ),
      permissions: [EPermission.MediaSequenceEditorBranchMutations],
    },
    {
      id: 'sounds',
      title: 'Baggrundsmusik',
      pageAlert: {
        header: 'Opsætning af baggrundsmusik',
        message:
          'Vælg den baggrundsmusik der skal bruges i din video. Du kan også uploade din egen baggrundsmusik.',
      },
      component: (
        <MediaSequenceSetupSound
          mediaSequence={mediaSequence}
          disabled={disabled}
        />
      ),
      permissions: [EPermission.MediaSequenceEditorBranchMutations],
    },
    ...(mediaSequence.type !== EMediaSequenceType.Strict
      ? [
          {
            id: 'branch-mutations',
            title: 'Videoeffekter',
            pageAlert: {
              header: 'Opsætning af videoeffekter',
              message:
                'Her kan du tilføje effekter til din video. Effekterne vil blive tilføjet i hele videoen.',
            },
            component: (
              <MediaSequenceSetupBranchMutations
                mediaSequence={mediaSequence}
                disabled={disabled}
              />
            ),
            permissions: [EPermission.MediaSequenceEditorBranchMutations],
          },
        ]
      : []),
    ...(mediaSequence.showTranscriptionsStep && !isTemplate(mediaSequence)
      ? [
          {
            id: 'subtitles',
            title: 'Undertekster',
            pageAlert: {
              header: 'Opsætning af undertekster',
              message:
                'Her kan du godkende og rette underteskterne i din video.',
            },
            component: (
              <MediaSequenceSetupTranscription
                mediaSequence={mediaSequence}
                disabled={
                  mediaSequence.transcriptionState ===
                  ETranscriptionState.Approved
                }
              />
            ),
            permissions: [EPermission.MediaSequenceEditorTranscription],
          } as MediaSequenceSetupStep,
        ]
      : []),
    ...(mediaSequence.state === EMediaSequenceState.Failed
      ? [
          {
            id: 'error',
            title: 'Fejl',
            pageAlert: {
              header: 'Smart Video er fejlet',
              message:
                'Der opstod desværre en uventet fejl under behandlingen af din video.',
              type: 'error',
            },
            component: (
              <MediaSequenceSetupError mediaSequence={mediaSequence} />
            ),
            permissions: [EPermission.MediaSequenceRead],
          } as MediaSequenceSetupStep,
        ]
      : []),
    ...(!isTemplate(mediaSequence)
      ? [
          {
            id: 'results',
            title: 'Resultat',
            pageAlert: {
              header: 'Resultat af Smart Video',
              message:
                mediaSequence.branches.length === 1
                  ? `I dette trin kan du se resultatet for det valgte format.`
                  : `I dette trin kan du se resultaterne for dine ${mediaSequence.branches.length} formater.`,
            },
            component: (
              <MediaSequenceSetupResult
                mediaSequence={mediaSequence}
                disabled={disabled}
              />
            ),
            permissions: [EPermission.MediaSequenceEditorResults],
          },
        ]
      : []),
    // {
    //   id: 'publish',
    //   title: 'Publicering',
    //   pageAlert: {
    //     header: 'Publicering af Smart Video',
    //     message: `I dette trin kan du publicere resultatet til dine ${mediaSequence.branches.length} formater på sociale medier.`,
    //   },
    //   component: (
    //     <MediaSequenceSetupPublish
    //       mediaSequence={mediaSequence}
    //       disabled={disabled}
    //     />
    //   ),
    //   permissions: [EPermission.ModuleSocialMedia],
    // },
  ];

  return result;
};

export const getCurrentProgressStepIndex = (
  mediaSequence: MediaSequenceResponse,
  steps: MediaSequenceSetupStep[],
  isAddDynamicOverlaysValid: boolean,
  hasPublishPermission: boolean
): number => {
  // If state is failed go to the error step
  if (mediaSequence.state === EMediaSequenceState.Failed) {
    const errorStep = steps.find((step) => step.id === 'error');
    if (errorStep) {
      return steps.indexOf(errorStep) + 1;
    }
  }

  // If it is processed and used has permissions to publish, go to the publish step
  if (
    mediaSequence.state === EMediaSequenceState.Processed &&
    hasPublishPermission
  ) {
    const publishStep = steps.find((step) => step.id === 'publish');
    if (publishStep) {
      return steps.indexOf(publishStep) + 1;
    }
  }

  // If any branch has a finished asset, go to the results step
  const anyBranchFinished = mediaSequence.branches.some((e) => e.asset);
  if (anyBranchFinished) {
    const resultState = steps.find((step) => step.id === 'results');
    if (resultState) {
      return steps.indexOf(resultState) + 1;
    }
  }

  // If subtitles are generated, go to that step
  if (
    mediaSequence.transcriptionState === ETranscriptionState.Generated ||
    mediaSequence.transcriptionState === ETranscriptionState.Pending
  ) {
    const transcriptionStep = steps.find((step) => step.id === 'subtitles');
    if (transcriptionStep) {
      return steps.indexOf(transcriptionStep) + 1;
    }
  }

  // If all dynamic overlay mutations are valid, go to next step
  if (isAddDynamicOverlaysValid) {
    const templateStep = steps.find((step) => step.id === 'template');
    if (templateStep) {
      return steps.indexOf(templateStep) + 2;
    }
  }

  // If it is a draft, go to the first step
  if (mediaSequence.state === EMediaSequenceState.Draft) {
    return 1;
  }

  // Else go to the last step
  return steps.length;
};
