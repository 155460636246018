import { Configuration as CoreConfiguration } from './core';

export class CoreBaseConfiguration extends CoreConfiguration {
  constructor() {
    super({
      credentials: 'include',
      basePath: process.env.REACT_APP_CORE_API_URL,
      fetchApi: async (url, init) => {
        // Fix for 411 Length Required on PUT with no body
        if (init?.method?.toUpperCase() === 'PUT' && init.body === undefined) {
          init.body = '{}';
          init.headers = {
            ...init.headers,
            'Content-Type': 'application/json',
          };
        }

        return fetch(url, init);
      },
    });
  }
}
