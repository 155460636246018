/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EEntityState } from './EEntityState';
import {
    EEntityStateFromJSON,
    EEntityStateFromJSONTyped,
    EEntityStateToJSON,
} from './EEntityState';
import type { OrganizationSimpleResponse } from './OrganizationSimpleResponse';
import {
    OrganizationSimpleResponseFromJSON,
    OrganizationSimpleResponseFromJSONTyped,
    OrganizationSimpleResponseToJSON,
} from './OrganizationSimpleResponse';
import type { UserSimpleResponse } from './UserSimpleResponse';
import {
    UserSimpleResponseFromJSON,
    UserSimpleResponseFromJSONTyped,
    UserSimpleResponseToJSON,
} from './UserSimpleResponse';

/**
 * 
 * @export
 * @interface FolderResponse
 */
export interface FolderResponse {
    /**
     * 
     * @type {Date}
     * @memberof FolderResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof FolderResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof FolderResponse
     */
    id: string;
    /**
     * 
     * @type {EEntityState}
     * @memberof FolderResponse
     */
    entityState: EEntityState;
    /**
     * 
     * @type {string}
     * @memberof FolderResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FolderResponse
     */
    itemCount: string;
    /**
     * 
     * @type {UserSimpleResponse}
     * @memberof FolderResponse
     */
    user: UserSimpleResponse;
    /**
     * 
     * @type {OrganizationSimpleResponse}
     * @memberof FolderResponse
     */
    organization: OrganizationSimpleResponse;
}

/**
 * Check if a given object implements the FolderResponse interface.
 */
export function instanceOfFolderResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "entityState" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "organization" in value;

    return isInstance;
}

export function FolderResponseFromJSON(json: any): FolderResponse {
    return FolderResponseFromJSONTyped(json, false);
}

export function FolderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FolderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'entityState': EEntityStateFromJSON(json['entityState']),
        'name': json['name'],
        'itemCount': json['itemCount'],
        'user': UserSimpleResponseFromJSON(json['user']),
        'organization': OrganizationSimpleResponseFromJSON(json['organization']),
    };
}

export function FolderResponseToJSON(value?: FolderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'entityState': EEntityStateToJSON(value.entityState),
        'name': value.name,
        'itemCount': value.itemCount,
        'user': UserSimpleResponseToJSON(value.user),
        'organization': OrganizationSimpleResponseToJSON(value.organization),
    };
}

