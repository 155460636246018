import { AssetResponse, ServiceTicketCreateRequest } from 'api/core';
import { useCreateTicket } from 'api/useServiceTicketApi';
import { AssetPreviewModal } from 'components/Asset/AssetPreviewModal';
import { AssetUpload } from 'components/Form/AssetUpload';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

interface ServiceTicketProps {
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const ServiceTicketForm = ({
  onSuccess,
  onCancel,
}: ServiceTicketProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateTicket();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const onAssetUploaded = async (asset: AssetResponse | null) => {
    if (!asset) return;
    setSelectedAssets((prev) => [...prev, asset]);
  };

  const [selectedAssets, setSelectedAssets] = useState<AssetResponse[]>([]);

  const { register, handleSubmit } = useForm<ServiceTicketCreateRequest>({
    defaultValues: {},
  });

  const onSubmit = handleSubmit(async (result) => {
    await createAsync({
      serviceTicketCreateRequest: {
        ...result,
        assetIds: selectedAssets.map((a) => a.id),
      },
    });
    onSuccess?.();
    setSelectedAssets([]);
  });

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Titel</span>
        </label>
        <input
          data-testid="service-ticket-title"
          {...register('title', { required: true })}
          type="text"
          className="input input-bordered"
        />
      </div>

      {/* TODO: Let user associate a project to the ticket (optional) 
      <div className="form-control">
        <label className="label">
          <span className="label-text">Vedrørende projekt</span>
        </label>
        <input
          {...register('projectId')}
          type="text"
          className="input input-bordered"
        />
      </div>
      */}

      {/* TODO: Let user associate a case to the ticket (optional)
      <div className="form-control">
        <label className="label">
          <span className="label-text">Vedrørende sag</span>
        </label>
        <input
          {...register('caseId')}
          type="text"
          className="input input-bordered"
        />
      </div>
       */}

      {/* TODO: Let user associate a MedSeq to the ticket (optional)
      <div className="form-control">
        <label className="label">
          <span className="label-text">Vedrørende Smart Video</span>
        </label>
        <input
          {...register('mediaSequenceId')}
          type="text"
          className="input input-bordered"
        />
      </div>
       */}

      {/* TODO: Let user associate a publicaiton to the ticket (optional)
      <div className="form-control">
        <label className="label">
          <span className="label-text">Vedrørende Smart Planner</span>
        </label>
        <input
          {...register('publicationId')}
          type="text"
          className="input input-bordered"
        />
      </div>
       */}

      <div className="form-control">
        <label className="label">
          <span className="label-text">Besked</span>
        </label>
        <textarea
          data-testid="service-ticket-message"
          {...register('message', { required: true })}
          className="textarea textarea-bordered"
          rows={8}
        />
      </div>

      <a
        className="btn btn-primary"
        onClick={() => fileInputRef.current?.click()}
      >
        Upload ny fil
      </a>

      <AssetUpload
        fileInputRef={fileInputRef}
        onAssetUploaded={onAssetUploaded}
        allowMultiple
        source="ServiceTicket"
      />

      <div className="flex flex-wrap gap-2">
        {selectedAssets.map((asset) => (
          <div
            key={asset.id}
            className="bg-gray-100 border border-gray-300 p-2 rounded-lg flex flex-col items-center"
          >
            <div className="text-xs text-gray-700 mb-1">
              {asset.originalFileName}
            </div>
            <AssetPreviewModal asset={asset} className="h-8" />
            <button
              type="button"
              className="btn btn-error"
              onClick={() =>
                setSelectedAssets((prev) =>
                  prev.filter((a) => a.id !== asset.id)
                )
              }
            >
              Fjern
            </button>
          </div>
        ))}
      </div>

      <div className="flex justify-center space-x-4 pt-4">
        <button
          data-testid="service-ticket-submit"
          className="btn btn-primary"
          disabled={isPendingCreate}
        >
          Opret
        </button>
        <button type="button" className="btn" onClick={onCancel}>
          Annuller
        </button>
      </div>
    </form>
  );
};
