import { ColumnDef } from '@tanstack/react-table';
import { EPermission, FolderResponse, FolderResponsePagedData } from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { useMemo, useState } from 'react';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { useDeleteFolder, useGetFolder } from 'api/useFoldersApi';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { FolderUpdate } from './FolderUpdate';
import { LinkCell } from 'components/Table/Cell/LinkCell';

interface TableProps {
  linkPath?: string;
  data?: FolderResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
}

export const FoldersTable = ({
  linkPath,
  data,
  isFetching,
  queryState,
}: TableProps) => {
  const [editTarget, setEditTarget] = useState<FolderResponse | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<FolderResponse | null>(null);

  const columns = useMemo(() => {
    const baseColumns: (ColumnDef<FolderResponse> | null)[] = [
      !linkPath
        ? null
        : {
            accessorKey: '_view',
            header: 'Vis',
            enableSorting: false,
            cell: (e) => (
              <LinkCell
                path={`${linkPath}/${e.row.original.id}`}
                tooltip="Vis"
              />
            ),
          },
      {
        accessorKey: 'name',
        header: 'Navn',
      },
      {
        accessorKey: 'itemCount',
        header: 'Indhold',
        enableSorting: false,
      },
      {
        accessorKey: 'user.name',
        header: 'Bruger',
        enableSorting: false,
      },
      {
        accessorKey: 'lastModifiedUtc',
        header: 'Sidst redigeret',
        cell: (e) => <DateTimeCell value={e.row.original.lastModifiedUtc} />,
      },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <div className="flex space-x-5 md:space-x-3">
            <ActionCell
              permissions={[EPermission.FolderWrite]}
              icon={
                <AnimatedIcon
                  icon="pencil-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setEditTarget(e.row.original)}
            />
            <ActionCell
              permissions={[EPermission.FolderDelete]}
              icon={
                <AnimatedIcon
                  icon="trash-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setDeleteTarget(e.row.original)}
              tooltip="Slet mappe"
            />
          </div>
        ),
      },
    ];
    return baseColumns.filter((e) => e !== null) as ColumnDef<FolderResponse>[];
  }, [setEditTarget, setDeleteTarget, linkPath]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {editTarget ? (
        <FolderUpdate
          id={editTarget?.id}
          isInitialOpen={true}
          onClosed={() => setEditTarget(null)}
        />
      ) : null}
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'name'}
          getter={useGetFolder}
          deleter={useDeleteFolder}
          descriptionFn={(entityTitle) =>
            `Er du sikker på du vil slette '${entityTitle}'? Du sletter kun mappen men ikke indholdet.`
          }
        />
      ) : null}
    </>
  );
};
