import {
  FolderCreateRequest,
  FolderResponse,
  FolderUpdateRequest,
} from 'api/core';
import { useCreateFolder, useUpdateFolder } from 'api/useFoldersApi';
import { useForm } from 'react-hook-form';

interface FolderFormProps {
  targetFolder?: FolderResponse;
  onSuccess?: () => void;
  onCancel?: () => void;
  inDialog?: boolean;
}

export const FolderForm = ({
  targetFolder,
  onSuccess,
  onCancel,
}: FolderFormProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateFolder();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateFolder();

  const { register, handleSubmit } = useForm<
    FolderCreateRequest | FolderUpdateRequest
  >({
    defaultValues: {
      ...targetFolder,
    },
  });

  const onSubmit = handleSubmit(async (result) => {
    if (targetFolder) {
      await updateAsync({
        id: targetFolder.id,
        folderUpdateRequest: result,
      });
    } else {
      await createAsync({
        folderCreateRequest: result,
      });
    }
    onSuccess?.();
  });

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Navn</span>
        </label>
        <input
          {...register('name', { required: true })}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="flex justify-center space-x-4 pt-4">
        <button
          className="btn btn-primary"
          disabled={isPendingCreate || isPendingUpdate}
        >
          {targetFolder ? 'Opdater' : 'Opret'}
        </button>
        <button type="button" className="btn" onClick={onCancel}>
          Annuller
        </button>
      </div>
    </form>
  );
};
