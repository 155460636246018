import { ComponentPropsWithoutRef } from 'react';
import { Link as RouterLink } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { ExternalLinkIcon } from 'lucide-react';

type LinkProps = {
  isExternal?: boolean;
} & ComponentPropsWithoutRef<typeof RouterLink>;

export const Link = ({
  isExternal,
  className,
  children,
  ...props
}: LinkProps) => {
  return (
    <RouterLink
      target={isExternal ? '_blank' : undefined}
      {...props}
      className={twMerge(
        'link link-primary',
        isExternal && 'flex flex-row items-baseline gap-1',
        className
      )}
    >
      <span>{children}</span>
      {isExternal && <ExternalLinkIcon className="w-[1em]" />}
    </RouterLink>
  );
};
