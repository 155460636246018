import {
  buildQueryState,
  useTableQueryState,
} from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useGetOrganizations } from 'api/useOrganizationsApi';
import { OrganizationResponse } from 'api/core';
import { useGetUsers } from 'api/useUsersApi';
import { UsersTable } from './components/User/UsersTable';

export const UsersView = () => {
  const { state } = useTableQueryState();
  const { data: users, isFetching: usersFetching } = useGetUsers(state, {
    includeForAllOrganizations: true,
  });

  const { data: allOrganizations, isFetching: allOrganizationsFetching } =
    useGetOrganizations(
      buildQueryState<OrganizationResponse>({
        pagination: {
          pageIndex: 0,
          pageSize: 1000,
        },
        sorting: [
          {
            id: 'name',
            desc: false,
          },
        ],
      })
    );

  return (
    <Page title={'Brugere'} breadcrumbs={[{ name: 'Brugere' }]}>
      <UsersTable
        data={users}
        allOrganizations={allOrganizations?.data ?? []}
        isFetching={usersFetching || allOrganizationsFetching}
        queryState={state}
        adminView={true}
      />
    </Page>
  );
};
