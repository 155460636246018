import { useMutation, useQuery } from '@tanstack/react-query';

import { TableQueryState } from 'components/Table/useTableQueryState';
import { queryClient } from 'query-client';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import { FolderCreateRequest, FoldersApi, FolderUpdateRequest } from './core';
import { MediaSequenceApiKeys } from './useMediaSequencesApi';

const FolderApiKeys = {
  GET_FOLDERS_SEARCH: 'folders-search',
  GET_FOLDERS: 'folders',
  GET_FOLDER: 'folder',
};

const client = new FoldersApi(new CoreBaseConfiguration());

export const useSearchFolders = ({ searchTerm }: { searchTerm?: string }) => {
  return useQuery({
    queryKey: [FolderApiKeys.GET_FOLDERS_SEARCH, searchTerm],
    queryFn: () =>
      client.foldersSearchGet({
        size: 50,
        searchTerm,
      }),
    enabled: searchTerm !== undefined || searchTerm != '',
  });
};

export const useGetFolders = (state?: TableQueryState) => {
  return useQuery({
    queryKey: [FolderApiKeys.GET_FOLDERS, state],
    queryFn: () =>
      client.foldersGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
  });
};

export const useGetFolder = (id?: string | null) => {
  return useQuery({
    queryKey: [FolderApiKeys.GET_FOLDER, id],
    queryFn: () => client.foldersIdGet({ id: id ?? '' }),
    enabled: id !== undefined && id !== null,
  });
};

export const useCreateFolder = () => {
  return useMutation({
    mutationFn: ({
      folderCreateRequest,
    }: {
      folderCreateRequest: FolderCreateRequest;
    }) => client.foldersPost({ folderCreateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [FolderApiKeys.GET_FOLDERS],
      });
      toast.success(`Mappen '${e.name}' er blevet oprettet`, {
        toastId: 'createFolder',
      });
    },
  });
};

export const useUpdateFolder = () => {
  return useMutation({
    mutationFn: ({
      id,
      folderUpdateRequest,
    }: {
      id: string;
      folderUpdateRequest: FolderUpdateRequest;
    }) => client.foldersIdPut({ id, folderUpdateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [FolderApiKeys.GET_FOLDERS],
      });
      queryClient.invalidateQueries({
        queryKey: [FolderApiKeys.GET_FOLDER, e.id],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE],
      });
      toast.success(`Mappen '${e.name}' er blevet opdateret`, {
        toastId: 'updateFolder',
      });
    },
  });
};

export const useDeleteFolder = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) => client.foldersIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [FolderApiKeys.GET_FOLDERS],
      });
      queryClient.invalidateQueries({
        queryKey: [FolderApiKeys.GET_FOLDER, e.id],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE],
      });
      toast.success(`Mappen '${e.name}' er blevet slettet`, {
        toastId: 'deleteFolder',
      });
    },
  });
};
