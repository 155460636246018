import {
  MediaSequenceAssetMutationResponse,
  MediaSequenceAssetMutationUpdateRequest,
  MediaSequenceBranchMutationResponse,
  MediaSequenceBranchMutationUpdateRequest,
  MediaSequenceResponse,
} from 'api/core';
import { useCallback, useEffect, useState } from 'react';
import { useMediaSequenceSetup } from './MediaSequenceSetupProvider';
import {
  getMediaSequenceAssetDynamicOverlayMutations,
  getMediaSequenceBranchDynamicOverlayMutations,
  isTemplate,
} from 'pages/MediaOrbit/media-sequence-utils';
import { AddDynamicOverlay } from './../MediaSequenceBranchMutation/Types/AddDynamicOverlay';
import { FormProvider, useForm } from 'react-hook-form';
import { useUpdateMediaSequenceBranchMutation } from 'api/useMediaSequenceBranchesApi';
import { useGetDynamicTemplate } from 'api/useDynamicTemplatesApi';
import {
  useGetMediaSequenceAssets,
  useGetValidateMediaSequenceAddDynamicOverlayMutations,
} from 'api/useMediaSequencesApi';
import { twMerge } from 'tailwind-merge';
import { CircleCheckBigIcon } from 'lucide-react';
import { useUpdateMediaSequenceAssetMutation } from 'api/useMediaSequenceAssetsApi';

interface MediaSequenceSetupAssetsProps {
  mediaSequence: MediaSequenceResponse;
  disabled: boolean;
}

export const MediaSequenceSetupTemplate = ({
  mediaSequence,
  disabled,
}: MediaSequenceSetupAssetsProps) => {
  const { steps, currentStepIndex, handlePrevious, handleNext } =
    useMediaSequenceSetup();

  const { data: isAddDynamicOverlayMutationsValid } =
    useGetValidateMediaSequenceAddDynamicOverlayMutations(mediaSequence.id);

  const { data: assets } = useGetMediaSequenceAssets(mediaSequence.id);

  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableText, setDisableText] = useState<string>('');

  const onRevalidateStep = useCallback(() => {
    if (isAddDynamicOverlayMutationsValid || isTemplate(mediaSequence)) {
      setDisableNext(false);
      setDisableText('');
    } else {
      setDisableNext(true);
      setDisableText('Alle skabelons værdierne skal udfyldes');
    }
  }, [isAddDynamicOverlayMutationsValid, mediaSequence]);

  useEffect(() => {
    onRevalidateStep();
  }, [mediaSequence, onRevalidateStep]);

  const branchDynamicOverlayMutations =
    getMediaSequenceBranchDynamicOverlayMutations(mediaSequence);

  const assetsDynamicOverlayMutations =
    getMediaSequenceAssetDynamicOverlayMutations(assets ?? []);

  return (
    <>
      <div
        id="templates-grid"
        data-testid="media-sequence-template-step"
        className="grid flex-grow h-full gap-y-2"
      >
        {branchDynamicOverlayMutations.map((mutation) => (
          <MediaSequenceSetupBranchMutation
            key={mutation.id}
            mediaSequence={mediaSequence}
            mutation={mutation}
            disabled={disabled}
          />
        ))}
        {assetsDynamicOverlayMutations.map((mutation) => (
          <MediaSequenceSetupAssetMutation
            key={mutation.id}
            mediaSequence={mediaSequence}
            mutation={mutation}
            disabled={disabled}
          />
        ))}
      </div>
      <div className="flex flex-col mt-4 mb-8">
        <div id="step-navigation" className="join justify-center mt-2">
          <button
            className="join-item btn"
            onClick={handlePrevious}
            disabled={currentStepIndex === 1}
          >
            Forrige trin
          </button>
          <button
            data-testid="media-sequence-next-step"
            className="join-item btn"
            onClick={handleNext}
            disabled={currentStepIndex >= steps.length || disableNext}
          >
            Næste trin
          </button>
        </div>
        {disableNext && disableText ? (
          <div className="text-center mt-2">
            <p className="text-sm text-gray-500">{disableText}</p>
          </div>
        ) : null}
      </div>
    </>
  );
};

interface MediaSequenceSetupBranchMutationProps {
  mediaSequence: MediaSequenceResponse;
  mutation: MediaSequenceBranchMutationResponse;
  disabled: boolean;
}

const MediaSequenceSetupBranchMutation = ({
  mediaSequence,
  mutation,
  disabled,
}: MediaSequenceSetupBranchMutationProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [isSuccess] = useState(true);

  // TODO: Determine isSuccess, so we can show which accordions needs user attention

  const { data: template } = useGetDynamicTemplate(
    mutation.addDynamicOverlay?.dynamicTemplateId
  );

  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateMediaSequenceBranchMutation();

  const methods = useForm<MediaSequenceBranchMutationUpdateRequest>({
    defaultValues: mutation,
  });

  const { handleSubmit, control } = methods;

  // We have to disable the form like this, if we set it in the useForm constructor it will not load in default values
  useEffect(() => {
    control._disableForm(disabled);
  }, [control, disabled]);

  const onSubmit = handleSubmit(async (result) => {
    await updateAsync({
      id: mutation.id,
      mediaSequenceBranchMutationUpdateRequest: result,
    });
    setIsAccordionOpen(false);
  });

  // If all fields are not overridable, we hide the template view
  const hideForTemplateView = template?.fields.every(
    (field) => !field.overridable
  );

  if (hideForTemplateView || !template) return null;

  return (
    <div
      className={twMerge(
        'collapse collapse-arrow bg-base-200 rounded-none',
        isAccordionOpen && 'collapse-open'
      )}
    >
      <input
        className="cursor-pointer"
        type="checkbox"
        checked={isAccordionOpen}
        onChange={() => setIsAccordionOpen((prev) => !prev)}
      />
      <div className="collapse-title flex justify-between items-center">
        <span>{template?.name}</span>
        {isSuccess ? (
          <CircleCheckBigIcon
            id="template-status"
            className="h-5 w-5 text-success"
          />
        ) : null}
      </div>

      <div className="collapse-content">
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className="space-y-2 w-full">
            <AddDynamicOverlay
              mediaSequenceId={mediaSequence.id}
              dynamicTemplateId={mutation?.addDynamicOverlay?.dynamicTemplateId}
              previewAspectRatio={
                mediaSequence.branches[0].width /
                mediaSequence.branches[0].height
              }
              inDialog={false}
              fallBackdynamicTemplateFieldValues={
                disabled
                  ? mutation?.addDynamicOverlay?.dynamicTemplateFieldValues
                  : undefined
              }
              templateView={true}
            />
            <div className="flex justify-center space-x-4 pt-4">
              <button
                data-testid="template-update"
                id="template-update"
                className="btn btn-primary"
                disabled={isPendingUpdate || disabled}
              >
                Opdater
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

interface MediaSequenceSetupAssetMutationProps {
  mediaSequence: MediaSequenceResponse;
  mutation: MediaSequenceAssetMutationResponse;
  disabled: boolean;
}

const MediaSequenceSetupAssetMutation = ({
  mediaSequence,
  mutation,
  disabled,
}: MediaSequenceSetupAssetMutationProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [isSuccess] = useState(true);

  // TODO: Determine isSuccess, so we can show which accordions needs user attention

  const { data: template } = useGetDynamicTemplate(
    mutation.addDynamicOverlay?.dynamicTemplateId
  );

  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateMediaSequenceAssetMutation();

  const methods = useForm<MediaSequenceAssetMutationUpdateRequest>({
    defaultValues: mutation,
  });

  const { handleSubmit, control } = methods;

  // We have to disable the form like this, if we set it in the useForm constructor it will not load in default values
  useEffect(() => {
    control._disableForm(disabled);
  }, [control, disabled]);

  const onSubmit = handleSubmit(async (result) => {
    await updateAsync({
      id: mutation.id,
      mediaSequenceAssetMutationUpdateRequest: result,
    });
    setIsAccordionOpen(false);
  });

  // If all fields are not overridable, we hide the template view
  const hideForTemplateView = template?.fields.every(
    (field) => !field.overridable
  );

  if (hideForTemplateView || !template) return null;

  return (
    <div
      className={twMerge(
        'collapse collapse-arrow bg-base-200 rounded-none',
        isAccordionOpen && 'collapse-open'
      )}
    >
      <input
        className="cursor-pointer"
        type="checkbox"
        checked={isAccordionOpen}
        onChange={() => setIsAccordionOpen((prev) => !prev)}
      />
      <div className="collapse-title flex justify-between items-center">
        <span>{template?.name}</span>
        {isSuccess ? (
          <CircleCheckBigIcon
            id="template-status"
            className="h-5 w-5 text-success"
          />
        ) : null}
      </div>

      <div className="collapse-content">
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className="space-y-2 w-full">
            <AddDynamicOverlay
              mediaSequenceId={mediaSequence.id}
              dynamicTemplateId={mutation?.addDynamicOverlay?.dynamicTemplateId}
              previewAspectRatio={
                mediaSequence.branches[0].width /
                mediaSequence.branches[0].height
              }
              inDialog={false}
              fallBackdynamicTemplateFieldValues={
                disabled
                  ? mutation?.addDynamicOverlay?.dynamicTemplateFieldValues
                  : undefined
              }
              templateView={true}
            />
            <div className="flex justify-center space-x-4 pt-4">
              <button
                data-testid="template-update"
                id="template-update"
                className="btn btn-primary"
                disabled={isPendingUpdate || disabled}
              >
                Opdater
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
