import { ColumnDef } from '@tanstack/react-table';
import { AngleResponse, AngleResponsePagedData } from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';

import { useMemo, useState } from 'react';
import { AngleUpdate } from './AngleUpdate';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { useDeleteAngle, useGetAngle } from 'api/useAnglesApi';
import { AssetPreviewModal } from 'components/Asset/AssetPreviewModal';
import { CircleArrowOutUpLeft } from 'lucide-react';

interface TableProps {
  projectId: string;
  data?: AngleResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
}

export const AnglesTable = ({
  projectId,
  data,
  isFetching,
  queryState,
}: TableProps) => {
  const [editTarget, setEditTarget] = useState<AngleResponse | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<AngleResponse | null>(null);

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<AngleResponse>[] = [
      {
        accessorKey: 'section.name',
        header: 'Sektion',
      },
      {
        accessorKey: 'name',
        header: 'Navn',
      },
      {
        accessorKey: 'order',
        header: 'Rækkefølge',
      },
      {
        accessorKey: 'northOffset',
        header: 'Nord forskydning',
        cell: (e) => {
          if (e.row.original.northOffset === null) {
            return 'Ikke sat';
          }
          return (
            <div className="flex items-center space-x-2">
              <CircleArrowOutUpLeft
                className="h-8 md:h-5 w-8 md:w-5"
                style={{ rotate: 45 + e.row.original.northOffset + 'deg' }}
              />
              <span>{e.row.original.northOffset}&deg;</span>
            </div>
          );
        },
      },
      {
        accessorKey: 'rendering.id',
        header: 'Rendering',
        enableSorting: false,
        cell: (e) => (
          <div className="max-w-48">
            <AssetPreviewModal asset={e.row.original.rendering} />
          </div>
        ),
      },
      {
        accessorKey: 'overlay.id',
        header: 'Overlay',
        enableSorting: false,
        cell: (e) => (
          <div className="max-w-48">
            <AssetPreviewModal asset={e.row.original.overlay} />
          </div>
        ),
      },
      {
        accessorKey: 'lastModifiedUtc',
        header: 'Sidst redigeret',
        cell: (e) => <DateTimeCell value={e.row.original.lastModifiedUtc} />,
      },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <div className="flex space-x-5 md:space-x-3">
            <ActionCell
              icon={
                <AnimatedIcon
                  icon="pencil-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setEditTarget(e.row.original)}
            />
            <ActionCell
              icon={
                <AnimatedIcon
                  icon="trash-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setDeleteTarget(e.row.original)}
            />
          </div>
        ),
      },
    ];
    return baseColumns;
  }, [setEditTarget, setDeleteTarget]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {editTarget ? (
        <AngleUpdate
          id={editTarget?.id}
          projectId={projectId}
          isInitialOpen={true}
          onClosed={() => setEditTarget(null)}
        />
      ) : null}
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'name'}
          getter={useGetAngle}
          deleter={useDeleteAngle}
        />
      ) : null}
    </>
  );
};
