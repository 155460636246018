import { CaseFieldResponse, EFieldType, FieldResponse } from 'api/core';

export const formatNumber = (value: string | number) => {
  const isDecimal = value.toString().includes('.');
  if (typeof value === 'string')
    value = isDecimal ? parseFloat(value) : parseInt(value);
  return new Intl.NumberFormat('da-DK').format(value);
};

export const formatCurrency = (value: string | number) => {
  return `${formatNumber(value)} kr.`;
};

export const formatArea = (value: string | number) => {
  return `${formatNumber(value)} m²`;
};

export const isNumericField = (field: FieldResponse) => {
  return (
    field.type === EFieldType.Area ||
    field.type === EFieldType.Currency ||
    field.type === EFieldType.Rent ||
    field.type === EFieldType.SubsidyArea ||
    field.type === EFieldType.Number
  );
};

export const formatBoolean = (value: string | boolean) => {
  if (value === true) return 'Ja';
  if (value === false) return 'Nej';
  if (value === '1') return 'Ja';
  if (value === '0') return 'Nej';
  if (value.localeCompare('True') === 0) return 'Ja';
  if (value.localeCompare('False') === 0) return 'Nej';
  if (value.localeCompare('Ja') === 0) return 'Ja';
  if (value.localeCompare('Nej') === 0) return 'Nej';
  return value ? 'Ja' : 'Nej';
};

export const formatByType = (
  value: string | null | undefined,
  type: EFieldType
) => {
  if (type !== EFieldType.Boolean && !value) return '';
  const caseFieldValue = value || '';

  if (type === EFieldType.Area) return formatArea(caseFieldValue);
  if (type === EFieldType.Currency) return formatCurrency(caseFieldValue);
  if (type === EFieldType.Date) return caseFieldValue; // Assume date is already formatted as wanted
  if (type === EFieldType.HousingType) return caseFieldValue;
  if (type === EFieldType.Longtext) return caseFieldValue;
  if (type === EFieldType.Number) return formatNumber(caseFieldValue);
  if (type === EFieldType.Rent) return formatCurrency(caseFieldValue);
  if (type === EFieldType.SubsidyArea) return formatArea(caseFieldValue);
  if (type === EFieldType.Text) return caseFieldValue;
  if (type === EFieldType.Boolean) return formatBoolean(caseFieldValue);

  return caseFieldValue;
};

export const formatFieldValue = (
  caseField: CaseFieldResponse,
  fields: FieldResponse[]
): string => {
  const type = fields.find((f) => f.id === caseField.fieldId)?.type;
  if (!type) return caseField.value || '';

  return formatByType(caseField.value, type);
};
