import { useGetFields } from 'api/useFieldsApi';
import {
  CaseCreateRequest,
  CaseMassUpsertRequest,
  ECaseState,
  EFieldType,
  FieldResponse,
} from 'api/core';
import { ControlledModal } from 'components/ControlledModal';
import { useEffect, useState } from 'react';
import { formatByType, isNumericField } from 'utils/format/field';
import { useMassUpsertCases } from 'api/useCasesApi';

interface ProjectCaseImportProps {
  projectId: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const ProjectCaseImport = ({
  projectId,
  trigger,
  isInitialOpen,
  onClosed,
}: ProjectCaseImportProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);
  const [csvContent, setCsvContent] = useState<string | null>(null);

  const { data: fields } = useGetFields(projectId);

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  const onFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length !== 1) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      setCsvContent(e.target?.result as string);
    };
    reader.readAsText(e.target.files[0]);
  };

  if (!fields) return null;

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Importer sager"
        className="max-w-[70%]"
        hideActionBar
      >
        <>
          <input type="file" accept=".csv" onChange={onFileChanged} />
          {csvContent ? (
            <div className="mt-4">
              <CaseImportCsv
                projectId={projectId}
                csvContent={csvContent}
                fields={fields.data}
                onSuccess={() => onModalStateChange(false)}
                onCancel={() => onModalStateChange(false)}
              />
            </div>
          ) : null}
        </>
      </ControlledModal>
    </>
  );
};

interface CaseImportCsvProps {
  projectId: string;
  csvContent: string;
  fields: FieldResponse[];
  onSuccess: () => void;
  onCancel: () => void;
}

interface CsvMap {
  numberDecimalSeperator: string;
  numberThousandSeperator: string;
  updateExistingCases: boolean;
  insertMissingCases: boolean;
  addressColumnIndex?: number;
  maskColumnIndex?: number;
  fields: {
    fieldId: string;
    columnIndex?: number;
  }[];
  rowSelected: boolean[];
}

export const CaseImportCsv = ({
  projectId,
  csvContent,
  fields,
  onSuccess,
  onCancel,
}: CaseImportCsvProps) => {
  const { mutateAsync: massUpsertCasesAsync, isPending } = useMassUpsertCases();

  const delimiter = determineDelimiter(csvContent);
  const csvTable = parseCsv(csvContent, delimiter);
  const [hasNondistinctAddresses, setHasNondistinctAddresses] =
    useState<boolean>(false);

  const [csvMap, setCsvMap] = useState<CsvMap>({
    numberDecimalSeperator: '.',
    numberThousandSeperator: ',',
    updateExistingCases: true,
    insertMissingCases: true,
    addressColumnIndex: undefined,
    maskColumnIndex: undefined,
    fields: fields.map((field) => ({ fieldId: field.id })),
    rowSelected: csvTable?.rows.map(() => true) ?? [],
  });

  const onSubmit = async () => {
    if (!csvTable) return;

    const cases: CaseCreateRequest[] = [];

    for (let i = 0; i < csvTable.rows.length; i++) {
      const isSelected = csvMap.rowSelected[i];
      if (!isSelected) continue;

      const address = csvTable.rows[i][csvMap.addressColumnIndex ?? 0];
      if (!address) continue;

      const mask = csvMap.maskColumnIndex
        ? csvTable.rows[i][csvMap.maskColumnIndex]
        : undefined;

      const caseFields = fields.map((field, fieldIndex) => {
        const columnIndex = csvMap.fields[fieldIndex].columnIndex;
        if (columnIndex === undefined) return undefined;

        let parsedValue = csvTable.rows[i][columnIndex];
        if (isNumericField(field)) {
          parsedValue = parsedValue
            .replaceAll(csvMap.numberThousandSeperator, '')
            .replaceAll(csvMap.numberDecimalSeperator, '.');
        }

        return {
          fieldId: field.id,
          value: parsedValue,
        };
      });

      cases.push({
        address: address,
        maskId: mask || '', // If empty its not overwritten
        caseFields: caseFields.filter((f) => f !== undefined) as {
          fieldId: string;
          value: string;
        }[],
        assetIds: [], // Not used
        state: ECaseState.Available, // Doesn't update, only for new cases
        dawaAddressId: null, // Not used
      });
    }

    const request: CaseMassUpsertRequest = {
      insertMissing: csvMap.insertMissingCases,
      updateExisting: csvMap.updateExistingCases,
      updateState: false,
      cases: cases,
    };

    console.log('Request', request);

    await massUpsertCasesAsync({
      projectId: projectId,
      caseMassUpsertRequest: request,
    });

    onSuccess();
  };

  useEffect(() => {
    const seenAddresses = new Set<string>();
    if (csvMap.addressColumnIndex === undefined) {
      setHasNondistinctAddresses(false);
      return;
    }

    if (!csvTable) {
      setHasNondistinctAddresses(false);
      return;
    }

    for (let i = 0; i < csvTable.rows.length; i++) {
      const isSelected = csvMap.rowSelected[i];
      if (!isSelected) continue;

      const address = csvTable.rows[i][csvMap.addressColumnIndex];
      if (!address) continue;

      if (seenAddresses.has(address)) {
        setHasNondistinctAddresses(true);
        return;
      }

      seenAddresses.add(address);
    }

    setHasNondistinctAddresses(false);
  }, [csvMap, setHasNondistinctAddresses, csvTable]);

  if (!csvTable) return null;

  if (!csvTable.headers.length) {
    return (
      <div>
        <div className="text-red-500">CSV-filen er tom.</div>
        <div className="flex justify-end space-x-4 pt-4">
          <button className="btn" onClick={onCancel}>
            Annuller
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse border border-gray-300 table">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 text-left">
              Egenskab
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Værdi
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Decimalseparator
            </td>
            <td className="border border-gray-300 px-4 py-2">
              <select
                className="w-full p-2 border rounded"
                value={csvMap.numberDecimalSeperator}
                onChange={(e) =>
                  setCsvMap({
                    ...csvMap,
                    numberDecimalSeperator: e.target.value,
                  })
                }
              >
                {['.', ','].map((seperator) => (
                  <option key={seperator} value={seperator}>
                    {seperator}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Tusindeseparator
            </td>
            <td className="border border-gray-300 px-4 py-2">
              <select
                className="w-full p-2 border rounded"
                value={csvMap.numberThousandSeperator}
                onChange={(e) =>
                  setCsvMap({
                    ...csvMap,
                    numberThousandSeperator: e.target.value,
                  })
                }
              >
                {['.', ','].map((seperator) => (
                  <option key={seperator} value={seperator}>
                    {seperator}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Opdater eksisterende sager
            </td>
            <td className="border border-gray-300 px-4 py-2">
              <input
                type="checkbox"
                className="toggle toggle-primary"
                checked={csvMap.updateExistingCases}
                onChange={(e) =>
                  setCsvMap({
                    ...csvMap,
                    updateExistingCases: e.target.checked,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Indsæt manglende sager
            </td>
            <td className="border border-gray-300 px-4 py-2">
              <input
                type="checkbox"
                className="toggle toggle-primary"
                checked={csvMap.insertMissingCases}
                onChange={(e) =>
                  setCsvMap({ ...csvMap, insertMissingCases: e.target.checked })
                }
              />
            </td>
          </tr>
        </tbody>
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 text-left">Type</th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              CSV Kolonne
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adresse</td>
            <td className="border border-gray-300 px-4 py-2">
              <select
                className="w-full p-2 border rounded"
                value={csvMap.addressColumnIndex ?? ''}
                onChange={(e) =>
                  setCsvMap({
                    ...csvMap,
                    addressColumnIndex: e.target.value
                      ? parseInt(e.target.value)
                      : undefined,
                  })
                }
              >
                <option value="">Vælg kolonne</option>
                {csvTable.headers.map((header, index) => {
                  if (!header) return null;
                  return (
                    <option key={index} value={index}>
                      {header}
                    </option>
                  );
                })}
              </select>
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Maske</td>
            <td className="border border-gray-300 px-4 py-2">
              <select
                className="w-full p-2 border rounded"
                value={csvMap.maskColumnIndex ?? ''}
                onChange={(e) =>
                  setCsvMap({
                    ...csvMap,
                    maskColumnIndex: e.target.value
                      ? parseInt(e.target.value)
                      : undefined,
                  })
                }
              >
                <option value="">Vælg kolonne</option>
                {csvTable.headers.map((header, index) => (
                  <option key={index} value={index}>
                    {header}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          {fields.map((field, fieldIndex) => (
            <tr key={field.id}>
              <td className="border border-gray-300 px-4 py-2">{field.name}</td>
              <td className="border border-gray-300 px-4 py-2">
                <select
                  className="w-full p-2 border rounded"
                  value={csvMap.fields[fieldIndex].columnIndex ?? ''}
                  onChange={(e) =>
                    setCsvMap({
                      ...csvMap,
                      fields: csvMap.fields.map((f, i) =>
                        i === fieldIndex
                          ? {
                              ...f,
                              columnIndex: e.target.value
                                ? parseInt(e.target.value)
                                : undefined,
                            }
                          : f
                      ),
                    })
                  }
                >
                  <option value="">Vælg kolonne</option>
                  {csvTable.headers.map((header, index) => (
                    <option key={index} value={index}>
                      {header}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex flex-col space-y-4">
        {hasNondistinctAddresses ? (
          <div className="text-red-500">
            Der er flere rækker med samme adresse.
          </div>
        ) : null}

        {csvMap.numberDecimalSeperator === csvMap.numberThousandSeperator ? (
          <div className="text-red-500">
            Decimalseparator og tusindeseparator må ikke være ens.
          </div>
        ) : null}

        {!csvMap.updateExistingCases && !csvMap.insertMissingCases ? (
          <div className="text-red-500">
            Der skal vælges mindst én handling.
          </div>
        ) : null}
      </div>

      {csvMap.addressColumnIndex !== undefined ? (
        <CsvPreview
          csvTable={csvTable}
          csvMap={csvMap}
          fields={fields}
          setCsvMap={setCsvMap}
        />
      ) : null}

      <div className="flex justify-end space-x-4 pt-4">
        <button className="btn" onClick={onCancel}>
          Annuller
        </button>
        <button
          className="btn btn-primary"
          onClick={onSubmit}
          disabled={
            csvMap.addressColumnIndex === undefined ||
            csvMap.numberDecimalSeperator === csvMap.numberThousandSeperator ||
            hasNondistinctAddresses ||
            (!csvMap.updateExistingCases && !csvMap.insertMissingCases) ||
            isPending
          }
        >
          Importer
        </button>
      </div>
    </div>
  );
};

interface CsvPreviewProps {
  csvTable: CsvTable;
  csvMap: CsvMap;
  setCsvMap: (csvMap: CsvMap) => void;
  fields: FieldResponse[];
}

const CsvPreview = ({
  csvTable,
  csvMap,
  setCsvMap,
  fields,
}: CsvPreviewProps) => {
  const showAddress = csvMap.addressColumnIndex !== undefined;
  const showMask = csvMap.maskColumnIndex !== undefined;

  return (
    <div className="overflow-x-auto mt-4">
      <table className="w-full border-collapse border border-gray-300 table">
        <thead>
          <tr className="bg-gray-100">
            <th>Skal importeres</th>
            {showAddress && (
              <th className="border border-gray-300 px-4 py-2">Adresse</th>
            )}
            {showMask && (
              <th className="border border-gray-300 px-4 py-2">Maske</th>
            )}
            {csvMap.fields.map((field, index) => {
              if (field.columnIndex === undefined) return null;
              return (
                <th
                  key={fields[index].id}
                  className="border border-gray-300 px-4 py-2"
                >
                  {fields[index].name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {csvTable.rows.map((row, rowIndex) => {
            const address =
              csvMap.addressColumnIndex !== undefined
                ? row[csvMap.addressColumnIndex]
                : undefined;
            if (!address) return null;

            return (
              <tr key={rowIndex}>
                <td className="border border-gray-300 px-4 py-2">
                  <input
                    type="checkbox"
                    className="toggle toggle-primary"
                    checked={csvMap.rowSelected[rowIndex]}
                    onChange={(e) =>
                      setCsvMap({
                        ...csvMap,
                        rowSelected: csvMap.rowSelected.map((r, i) =>
                          i === rowIndex ? e.target.checked : r
                        ),
                      })
                    }
                  />
                </td>
                {showAddress && (
                  <td className="border border-gray-300 px-4 py-2">
                    {row[csvMap.addressColumnIndex ?? 0]}
                  </td>
                )}
                {showMask && (
                  <td className="border border-gray-300 px-4 py-2">
                    {row[csvMap.maskColumnIndex ?? 0]}
                  </td>
                )}
                {csvMap.fields.map((field, fieldIndex) => {
                  if (field.columnIndex === undefined) return null;
                  let parsedValue = row[field.columnIndex];
                  const projectField = fields[fieldIndex];
                  if (
                    projectField.type === EFieldType.Area ||
                    projectField.type === EFieldType.Currency ||
                    projectField.type === EFieldType.Rent ||
                    projectField.type === EFieldType.SubsidyArea ||
                    projectField.type === EFieldType.Number
                  ) {
                    parsedValue = parsedValue
                      .replaceAll(csvMap.numberThousandSeperator, '')
                      .replaceAll(csvMap.numberDecimalSeperator, ',');
                  }
                  return (
                    <td
                      key={fields[fieldIndex].id}
                      className="border border-gray-300 px-4 py-2"
                    >
                      {formatByType(parsedValue, projectField.type)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

interface CsvTable {
  headers: string[];
  rows: string[][];
}

const determineDelimiter = (content: string): string => {
  const semicolonCount = content.split(';').length;
  const commaCount = content.split(',').length;
  return semicolonCount > commaCount ? ';' : ',';
};

const parseCsv = (content: string, delimiter: string): CsvTable | null => {
  const rows: string[][] = [];
  let currentRow: string[] = [];
  let currentField = '';
  let inQuotes = false;

  for (let i = 0; i < content.length; i++) {
    const char = content[i];
    const nextChar = content[i + 1];

    if (char === '"') {
      if (inQuotes && nextChar === '"') {
        // Handle double quotes inside a quoted field
        currentField += '"';
        i++; // Skip the next quote
      } else {
        // Toggle quote state
        inQuotes = !inQuotes;
      }
    } else if (char === delimiter && !inQuotes) {
      // If we encounter a semicolon outside of quotes, it's a field separator
      currentRow.push(currentField);
      currentField = '';
    } else if ((char === '\n' || char === '\r') && !inQuotes) {
      // If we encounter a newline outside of quotes, it marks the end of a row
      if (currentField.length || currentRow.length) {
        currentRow.push(currentField);
        rows.push(currentRow);
      }
      currentRow = [];
      currentField = '';
    } else {
      currentField += char;
    }
  }

  // Push the last row if any data remains
  if (currentField.length || currentRow.length) {
    currentRow.push(currentField);
    rows.push(currentRow);
  }

  // Extract headers
  if (rows.length == 0) return { headers: [], rows: [] };

  const headers = rows.shift();
  if (!headers) return { headers: [], rows: [] };

  return { headers, rows };
};
