import { Cell, flexRender, Row, Table } from '@tanstack/react-table';
import {
  ArrowDownNarrowWideIcon,
  ArrowUpDownIcon,
  ArrowUpNarrowWideIcon,
  SearchIcon,
} from 'lucide-react';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useDebounce } from 'utils/useDebounce';

interface CustomTableProps<T extends object> {
  table: Table<T>;
  onRowClick?: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: Row<T>
  ) => void;
  onCellClick?: (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    row: Cell<T, unknown>
  ) => void;
  isLoading?: boolean;
  hideHover?: boolean;
  hidePagination?: boolean;
  showFooter?: boolean;
  hideHeader?: boolean;
  hideSearch?: boolean;
  showToggleArchived?: boolean;
}

export const CustomTable = <T extends object>({
  table,
  onRowClick,
  onCellClick,
  isLoading,
  hideHover,
  hidePagination,
  showFooter,
  hideHeader,
  hideSearch,
  showToggleArchived,
}: CustomTableProps<T>) => {
  const showSearch = table.getState().globalFilter != null && !hideSearch;

  return (
    <div>
      {showSearch || showToggleArchived ? (
        <div className="flex justify-between items-center mb-4">
          {showSearch && (
            <div className="flex-grow mr-4">
              <SearchField
                initialValue={table.getState().globalFilter ?? ''}
                onChange={(value) => table.setGlobalFilter(value)}
              />
            </div>
          )}
          {showToggleArchived ? (
            <div className="flex-shrink-0">
              <ToggleShowArchived
                initialValue={
                  table.options.meta?.queryState?.showArchived ?? false
                }
                onChange={(value) =>
                  table.options.meta?.queryState?.setShowArchived(value)
                }
                isLoading={isLoading}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      <div className="overflow-x-auto">
        <table
          className={twMerge(hideHover ? undefined : 'table-zebra', 'table')}
        >
          {!hideHeader ? (
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none flex'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: (
                                <ArrowUpNarrowWideIcon className="ml-2 h-4 w-4" />
                              ),
                              desc: (
                                <ArrowDownNarrowWideIcon className="ml-2 h-4 w-4" />
                              ),
                            }[header.column.getIsSorted() as string] ??
                              (header.column.getCanSort() ? (
                                <ArrowUpDownIcon className="ml-2 h-4 w-4" />
                              ) : null)}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
          ) : null}
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                className={twMerge(
                  hideHover ? undefined : 'hover',
                  onRowClick != null && 'cursor-pointer'
                )}
                key={row.id}
                onClick={(event) => onRowClick?.(event, row)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    onClick={(event) => onCellClick?.(event, cell)}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {showFooter ? (
            <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          ) : null}
        </table>
      </div>

      {hidePagination && isLoading ? (
        <div className="w-full flex justify-start mt-4">
          <span className="loading loading-spinner loading-md ml-4"></span>
        </div>
      ) : null}

      {!hidePagination ? (
        <div className="flex flex-col space-y-2 md:flex-row justify-between items-center">
          <div className="w-1/3 flex justify-center md:justify-start">
            {isLoading ? (
              <span className="loading loading-spinner loading-md ml-4"></span>
            ) : null}
          </div>
          <div className="w-1/3 flex justify-center">
            <div className="join mt-2">
              <button
                className="join-item btn"
                onClick={() => table.previousPage()}
                disabled={table.getState().pagination.pageIndex === 0}
              >
                Forrige
              </button>
              <button className="join-item btn btn-disabled">
                {table.getState().pagination.pageIndex + 1}
              </button>
              <button
                className="join-item btn"
                onClick={() => table.nextPage()}
                disabled={
                  table.getState().pagination.pageIndex ===
                  table.getPageCount() - 1
                }
              >
                Næste
              </button>
            </div>
          </div>
          <div className="w-1/3 flex justify-center md:justify-end">
            <select
              className="select select-bordered select-sm max-w-xs"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Vis {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : null}
    </div>
  );
};

interface SearchFieldProps {
  initialValue: string;
  onChange: (value: string) => void;
  isLoading?: boolean;
}

const SearchField = ({
  initialValue,
  onChange,
  isLoading,
}: SearchFieldProps) => {
  const [query, setQuery] = useState(initialValue);
  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    onChange(debouncedQuery);
    // Don't want to react to onChange changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  return (
    <label className="input input-bordered flex items-center gap-2">
      {isLoading ? (
        <span className="loading loading-spinner loading-xs"></span>
      ) : (
        <SearchIcon className="w-4 h-4 opacity-70" />
      )}
      <input
        type="text"
        className="grow"
        placeholder="Søg efter noget"
        value={query}
        onChange={(value) => setQuery(value.target.value)}
      />
    </label>
  );
};

interface ToggleShowArchivedProps {
  initialValue: boolean;
  onChange: (value: boolean) => void;
  isLoading?: boolean;
}

const ToggleShowArchived = ({
  initialValue,
  onChange,
  isLoading,
}: ToggleShowArchivedProps) => {
  const [showArchived, setShowArchived] = useState(initialValue);

  const handleToggle = () => {
    const newValue = !showArchived;
    setShowArchived(newValue);
    onChange(newValue);
  };

  return (
    <label className="cursor-pointer label">
      <span className="label-text me-2">Vis arkiverede</span>
      <input
        type="checkbox"
        id="showArchived"
        name="showArchived"
        checked={showArchived}
        onChange={handleToggle}
        className="toggle toggle-primary"
        disabled={isLoading}
      />
    </label>
  );
};
