import { useGetProject } from 'api/useProjectsApi';
import { Page } from 'layouts/Admin/Page';
import { useParams } from 'react-router';
import { SectionsTable } from './components/Section/SectionsTable';
import { useTableQueryState } from 'components/Table/useTableQueryState';
import { SectionCreate } from './components/Section/SectionCreate';
import { useGetSections } from 'api/useSectionsApi';

export const ProjectSections = () => {
  const { id } = useParams<{ id: string }>();
  const { state } = useTableQueryState();

  const { data: project } = useGetProject(id, true);
  const { data: fields, isFetching: isFetchingSections } = useGetSections(
    id,
    state
  );

  return (
    <Page
      title={project?.name}
      breadcrumbs={[
        { name: 'Projekter' },
        { name: project?.name ?? '' },
        { name: 'Boligvælger' },
        { name: 'Sektioner' },
      ]}
    >
      <SectionCreate
        projectId={id as string}
        trigger={
          <div className="flex justify-start py-4 w-full">
            <button className="btn btn-primary mr-2">Opret sektion</button>
          </div>
        }
      />
      <SectionsTable
        data={fields}
        isFetching={isFetchingSections}
        queryState={state}
      />
    </Page>
  );
};
