import {
  buildQueryState,
  useTableQueryState,
} from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import {
  useCloneMediaSequenceTemplate,
  useGetMediaSequenceTemplates,
} from 'api/useMediaSequenceAdministrationApi';
import { MediaSequencesTable } from 'pages/MediaOrbit/components/MediaSequence/MediaSequencesTable';
import { useState } from 'react';
import { useGetOrganizations } from 'api/useOrganizationsApi';
import {
  EPermission,
  MediaSequenceResponse,
  OrganizationResponse,
} from 'api/core';
import { ControlledModal } from 'components/ControlledModal';
import { useForm } from 'react-hook-form';
import { useAuth } from 'auth/AuthProvider';
import { EntitySelectMultiple } from 'components/Select/EntitySelectMultiple';

export const MediaSequenceTemplatesView = () => {
  const { state } = useTableQueryState();
  const { data, isFetching } = useGetMediaSequenceTemplates(state);
  const { hasPermissions } = useAuth();

  return (
    <Page
      title={'Smart Video skabeloner'}
      breadcrumbs={[{ name: 'Smart Video skabeloner' }]}
    >
      <MediaSequenceCloneTemplate
        trigger={
          <div className="flex justify-start py-4 w-full">
            <button className="btn btn-primary mr-2">Klon skabelon</button>
          </div>
        }
      />
      <MediaSequencesTable
        data={data}
        isFetching={isFetching}
        queryState={state}
        showActions={false}
        showState={false}
        showTranscriptionState={false}
        showUser={false}
        showFolder={false}
        showOrganization={true}
        showType={hasPermissions([
          EPermission.MediaSequenceGlobalTemplateAdministrator,
        ])}
        showCategory={hasPermissions([
          EPermission.MediaSequenceGlobalTemplateAdministrator,
        ])}
      />
    </Page>
  );
};

interface MediaSequenceCloneTemplateProps {
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const MediaSequenceCloneTemplate = ({
  trigger,
  isInitialOpen,
  onClosed,
}: MediaSequenceCloneTemplateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { data: allOrganizations } = useGetOrganizations(
    buildQueryState<OrganizationResponse>({
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    })
  );

  const { data: allTemplates } = useGetMediaSequenceTemplates(
    buildQueryState({
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    })
  );

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  if (!allOrganizations) return null;
  if (!allTemplates) return null;

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Klon Smart Video skabelon"
        hideActionBar
      >
        <MediaSequenceCloneForm
          allOrganizations={allOrganizations.data}
          allTemplates={allTemplates.data.sort((a, b) =>
            `${a.organization?.name} - ${a.name}`.localeCompare(
              `${b.organization?.name} - ${b.name}`
            )
          )}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
        />
      </ControlledModal>
    </>
  );
};

interface MediaSequenceCloneFormProps {
  allOrganizations: OrganizationResponse[];
  allTemplates: MediaSequenceResponse[];
  onSuccess: () => void;
  onCancel: () => void;
  inDialog?: boolean;
}

export const MediaSequenceCloneForm = ({
  allOrganizations,
  allTemplates,
  onSuccess,
  onCancel,
}: MediaSequenceCloneFormProps) => {
  const { mutateAsync: cloneAsync, isPending: isPendingClone } =
    useCloneMediaSequenceTemplate();

  const { register, handleSubmit, setValue } = useForm<{
    targetOrganizationIds: string[];
    templateIds: string[];
  }>({
    defaultValues: {},
  });

  const onSubmit = handleSubmit(async (result) => {
    for (const targetOrganizationId of result.targetOrganizationIds) {
      for (const templateId of result.templateIds) {
        const templateName = allTemplates.find(
          (e) => e.id === templateId
        )?.name;
        if (!templateName) continue;

        await cloneAsync({
          mediaSequenceTemplateCloneRequest: {
            targetOrganizationId: targetOrganizationId,
            templateId,
            name: templateName,
          },
        });
      }
    }

    onSuccess?.();
  });

  const onTemplateSelected = (templates: MediaSequenceResponse[] | null) => {
    setValue('templateIds', templates?.map((e) => e.id) ?? []);
  };

  const onOrganizationSelected = (
    organizations: OrganizationResponse[] | null
  ) => {
    setValue('targetOrganizationIds', organizations?.map((e) => e.id) ?? []);
  };

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Skabeloner der skal klones</span>
        </label>
        <EntitySelectMultiple<MediaSequenceResponse>
          data={allTemplates}
          renderFormat={(template) => (
            <>
              {template.organization?.name} - {template.name}
            </>
          )}
          onSelect={onTemplateSelected}
          initialValue={[]}
          searchPropertyKey="name"
          inDialog={true}
        />
        <input
          {...register('templateIds', { required: true })}
          type="text"
          className="hidden"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">
            Organisationer der skal modtage klon af skabelonerne
          </span>
        </label>
        <EntitySelectMultiple<OrganizationResponse>
          data={allOrganizations}
          renderFormat={(format) => <>{format.name}</>}
          onSelect={onOrganizationSelected}
          initialValue={[]}
          searchPropertyKey="name"
          inDialog={true}
        />
        <input
          {...register('targetOrganizationIds', { required: true })}
          type="text"
          className="hidden"
        />
      </div>

      <div className="flex justify-center space-x-4 pt-4">
        <button className="btn btn-primary" disabled={isPendingClone}>
          {'Klon'}
        </button>
        {onCancel ? (
          <button type="button" className="btn" onClick={onCancel}>
            Annuller
          </button>
        ) : null}
      </div>
    </form>
  );
};
