/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CaseCreateRequest } from './CaseCreateRequest';
import {
    CaseCreateRequestFromJSON,
    CaseCreateRequestFromJSONTyped,
    CaseCreateRequestToJSON,
} from './CaseCreateRequest';

/**
 * 
 * @export
 * @interface CaseMassUpsertRequest
 */
export interface CaseMassUpsertRequest {
    /**
     * 
     * @type {Array<CaseCreateRequest>}
     * @memberof CaseMassUpsertRequest
     */
    cases: Array<CaseCreateRequest>;
    /**
     * 
     * @type {boolean}
     * @memberof CaseMassUpsertRequest
     */
    updateExisting: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CaseMassUpsertRequest
     */
    insertMissing: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CaseMassUpsertRequest
     */
    updateState: boolean;
}

/**
 * Check if a given object implements the CaseMassUpsertRequest interface.
 */
export function instanceOfCaseMassUpsertRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cases" in value;
    isInstance = isInstance && "updateExisting" in value;
    isInstance = isInstance && "insertMissing" in value;
    isInstance = isInstance && "updateState" in value;

    return isInstance;
}

export function CaseMassUpsertRequestFromJSON(json: any): CaseMassUpsertRequest {
    return CaseMassUpsertRequestFromJSONTyped(json, false);
}

export function CaseMassUpsertRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaseMassUpsertRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cases': ((json['cases'] as Array<any>).map(CaseCreateRequestFromJSON)),
        'updateExisting': json['updateExisting'],
        'insertMissing': json['insertMissing'],
        'updateState': json['updateState'],
    };
}

export function CaseMassUpsertRequestToJSON(value?: CaseMassUpsertRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cases': ((value.cases as Array<any>).map(CaseCreateRequestToJSON)),
        'updateExisting': value.updateExisting,
        'insertMissing': value.insertMissing,
        'updateState': value.updateState,
    };
}

