import { Step } from 'react-joyride';

export const ORGANIZATION_USERS_GUIDE_STEPS: Step[] = [
  {
    target: '#users-overview',
    title: 'Velkommen til brugeroversigten',
    content:
      'Dette er dit overblik over alle brugere i din organisation. Hvor du kan oprette, redigere og slette brugere.',
    disableBeacon: true,
  },
  {
    target: '#users-table',
    title: 'Denne tabel viser alle brugere',
    content:
      'Ud fra hver bruger kan du se noget information om brugeren, samt tage forskellige handlinger.',
    disableBeacon: true,
  },
  {
    target: '#users-add-user',
    title: 'Opret bruger',
    content: 'Tryk på knappen for at oprette en ny bruger i din organisation.',
    disableBeacon: true,
  },

  {
    target: '#users-table-name',
    title: 'Navn',
    content:
      'Repræsenterer brugerens fulde navn, som bliver brugt til skabeloner og mere.',
    disableBeacon: true,
  },
  {
    target: '#users-table-title',
    title: 'Titel',
    content:
      'Repræsenterer brugerens titel, som bliver brugt til skabeloner og mere.',
    disableBeacon: true,
  },
  {
    target: '#users-table-email',
    title: 'Email',
    content:
      'Repræsenterer brugerens email, som bliver brugt til login og kommunikation.',
    disableBeacon: true,
  },
  {
    target: '#users-table-roles',
    title: 'Roller',
    content:
      'Repræsenterer brugerens roller, som siger noget om hvilke rettigheder brugeren har.',
    disableBeacon: true,
  },
  {
    target: '#users-table-permissions',
    title: 'Rettigheder',
    content:
      'Repræsenterer brugerens rettigheder, som er udledt fra brugerens roller. Rettinghederne siger noget om, hvad brugeren kan og ikke kan.',
    disableBeacon: true,
  },
  {
    target: '#users-table-edit',
    title: 'Rediger bruger',
    content: 'Tryk på blyanten for at redigere brugerens informationer',
    disableBeacon: true,
  },
  {
    target: '#users-table-delete',
    title: 'Slet bruger',
    content: 'Tryk på skraldespanden for at slette brugeren.',
    disableBeacon: true,
  },
];
