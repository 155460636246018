import { captureException } from '@sentry/react';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { ModelError, ResponseError } from 'api/core';
import { toast } from 'react-toastify';

const handleError = async (error: Error, id: string | number) => {
  const castedError = error as ResponseError;
  if (castedError.response) {
    const innerError = (await castedError.response.json()) as ModelError;
    const innerErrorDetails = innerError?.details?.[0];
    const message = innerErrorDetails
      ? `${innerError.errorMessage}: ${innerErrorDetails}`
      : innerError.errorMessage;
    return toast.error(message, {
      toastId: id,
      autoClose: innerErrorDetails ? 10 * 1000 : undefined,
    });
  }
  throw error;
};

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      handleError(error, query.queryHash).catch((err) => {
        captureException(err);
        toast.error(
          'Der skete en ukendt fejl. Der er blevet sendt en fejlrapport til vores udviklere.'
        );
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _1, _2, mutation) => {
      handleError(error, mutation.mutationId).catch((err) => {
        captureException(err);
        toast.error(
          'Der skete en ukendt fejl. Der er blevet sendt en fejlrapport til vores udviklere.'
        );
      });
    },
  }),
});
