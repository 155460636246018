import { Step } from 'react-joyride';

export const MEDIA_SEQEUNCE_SET_UP_STEPS: Step[] = [
  {
    target: '#step-overview',
    title: 'Velkommen til Smart Video',
    content:
      'Dette er dit overblik over de trin, du skal igennem for at lave din video. Du kan altid se, hvor langt du er i processen via procesbaren. Når alle trinene er gennemført, er din video klar.',
    disableBeacon: true,
  },
  {
    target: '#step-navigation',
    title: 'Navigering',
    content:
      'Du kan altid skifte trin ved at trykke på knapperne. Det næste trin er inaktivt, indtil du har udfyldt de nødvendige felter.',
    disableBeacon: true,
  },
  // --- Templates --- //
  {
    target: '#templates-grid',
    title: 'Skabelon oversigt',
    content:
      'Her kan du se alle de skabeloner der tilhører videoen, hvor du skal udfylde nogle værdier. Du kan klikke skabelon navnet for at folde den ud og ind.',
    disableBeacon: true,
  },
  {
    target: '#template-status',
    title: 'Skabelon status',
    content:
      'Skabelonerne har nogle påkrævede felter, som du skal udfylde, før du kan gå videre. Du kan se, om der er felter der mangler at blive udfyldt, ved at se på status ikonet.',
    disableBeacon: true,
  },
  {
    target: '#template-fields',
    title: 'Skabelon felter',
    content:
      'Her er alle de felter, du skal udfylde per skabelon. Du kan se, hvilke felter der er påkrævet og hvilke der er valgfrie.',
    disableBeacon: true,
  },
  {
    target: '#template-preview',
    title: 'Forhåndsvisning',
    content:
      'Her er en forhåndsvisning af skabelonen med de værdier du har indtastet. Forhåndsvisningen opdateres automatisk, når du ændrer i felterne.',
    disableBeacon: true,
  },
  {
    target: '#template-update',
    title: 'Opdater skabelon',
    content:
      'Når du er færdig med at udfylde skabelonen, kan du trykke på knappen for at opdatere den. Du kan altid gå tilbage og ændre i skabelonen. Når du har opdateret alle skabeloner kan du forsætte til næste trin.',
    disableBeacon: true,
  },
  // --- Assets --- //
  {
    target: '#assets-grid',
    title: 'Fil oversigt',
    content: 'Her kan du se alle filer, du har tilføjet til din video.',
    disableBeacon: true,
  },
  {
    target: '#assets-add-asset',
    title: 'Tilføj filer',
    content:
      'Tryk på knappen for at tilføje en ny fil til din video. Du kan vælge mellem filer, der allerede er tilføjet eller tilføje en ny fil.',
    disableBeacon: true,
  },
  {
    target: '#assets-asset',
    title: 'Fil',
    content:
      'Her kan du se din fil. Du kan se, hvilken fil det er, hvilke effekter der er tilføjet og hvilken placering filen har i videoen. Du kan desuden også redigere og slette filen.',
    disableBeacon: true,
  },
  {
    target: '#assets-move-asset',
    title: 'Fil rækkefølge',
    content:
      'Du kan så let som ingenting ændre rækkefølgen på dine filer ved at tage fat i ikonet og rykke rundt på dem.',
    disableBeacon: true,
  },
  {
    target: '#assets-move-asset-arrows',
    title: 'Fil rækkefølge',
    content:
      'Du kan så let som ingenting ændre rækkefølgen på dine filer ved at bruge pilene. Du kan flytte filen op og ned i rækkefølgen.',
    disableBeacon: true,
  },
  {
    target: '#assets-add-asset-mutation',
    title: 'Tilføj fil effekter',
    content:
      'Du kan tilføje effekter til dine filer ved at trykke på knappen. Du kan vælge mellem forskellige effekter og tilpasse dem efter behov. Nogle effekter kræver, at du har en bestemt filtype, mens andre kun vil forekomme en gang på filen.',
    disableBeacon: true,
  },
  {
    target: '#assets-replace-asset',
    title: 'Udskift fil',
    content:
      'Denne fil er en kopi fra skabelonen. Du kan udskifte filen ved at trykke på knappen. Du kan også redigere filen, så den passer til dine behov.',
    disableBeacon: true,
  },
  {
    target: '#assets-approve-asset',
    title: 'Behold fil',
    content:
      'Denne fil er en kopi fra skabelonen. Du kan beholde filen ved at trykke på knappen. Du kan også redigere filen, så den passer til dine behov.',
    disableBeacon: true,
  },
  {
    target: '#assets-edit-asset',
    title: 'Rediger fil',
    content:
      'Tryk på knappen for at skifte filen ud eller ændre på det navn, den har i oversigten.',
    disableBeacon: true,
  },
  {
    target: '#assets-delete-asset',
    title: 'Slet fil',
    content: 'Tryk på knappen for at slette filen.',
    disableBeacon: true,
  },
  {
    target: '#assets-edit-asset-mutation',
    title: 'Rediger fil-effekter',
    content: 'Tryk på knappen for redigere en fil-effekt.',
    disableBeacon: true,
  },
  {
    target: '#assets-edit-asset-mutation-view',
    title: 'Vis fil-effekter',
    content: 'Tryk på knappen for at se en eksisterende fil-effekt.',
    disableBeacon: true,
  },
  {
    target: '#assets-delete-asset-mutation',
    title: 'Slet fil-effekter',
    content: 'Tryk på knappen for at slette en fil-effekt.',
    disableBeacon: true,
  },
  {
    target: '#assets-remove-assets',
    title: 'Slet alle filer',
    content: 'Tryk på knappen for at slette alle filerne.',
    disableBeacon: true,
  },
  // --- Branches --- //
  {
    target: '#branches-overview',
    title: 'Format oversigt',
    content:
      'Her kan du se alle de formater, din video vil blive lavet i. Du kan tilføje og fjerne formater efter behov.',
    disableBeacon: true,
  },
  {
    target: '#branches-toggle-branch',
    title: 'Slå format til/fra',
    content:
      'Tryk på knappen for at styre om din video skal laves i dette format.',
    disableBeacon: true,
  },
  // --- Sound --- //
  {
    target: '#audio-overview',
    title: 'Baggroundsmusik oversigt',
    content:
      'Her kan du styre baggrundsmusikken til din video. Du kan tilføje og fjerne baggrundsmusik samt styre lydniveauet efter behov.',
    disableBeacon: true,
  },
  {
    target: '#audio-select',
    title: 'Vælg baggrundsmusik',
    content:
      'Her kan du vælge baggrundsmusikken til din video. Du kan bruge eksisterende musik eller uploade din egen musik.',
    disableBeacon: true,
  },
  {
    target: '#audio-preview',
    title: 'Forhåndsafspilning af baggrundsmusik',
    content:
      'Her kan du afspille baggrundsmusikken for at høre, hvordan den lyder i videoen. Lydniveauet på forhåndsafspilningen er det samme som i videoen.',
    disableBeacon: true,
  },
  {
    target: '#audio-volume',
    title: 'Lydniveau',
    content:
      'Her kan du styre lydniveauet på baggrundsmusikken. Du kan skrue op og ned for lyden, så den passer til din video.',
    disableBeacon: true,
  },
  {
    target: '#audio-remove',
    title: 'Fjern baggrundsmusik',
    content: 'Tryk på knappen for at fjerne baggrundsmusikken fra din video.',
    disableBeacon: true,
  },
  {
    target: '#audio-update',
    title: 'Slå format til/fra',
    content:
      'Tryk på knappen for at gemme opdateringerne på baggrundsmusikken.',
    disableBeacon: true,
  },
  // --- Branch mutations --- //
  {
    target: '#branch-mutations-overview',
    title: 'Videoeffekter oversigt',
    content:
      'Her kan du se alle de videoeffekter, der er tilføjet til din video.',
    disableBeacon: true,
  },
  {
    target: '#branch-mutations-add-mutation',
    title: 'Tilføj videoeffekt',
    content:
      'Tryk på knappen for at tilføje en ny videoeffekt til din video. Du kan vælge mellem forskellige effekter og tilpasse dem efter dit behov. Nogle effekter kræver, at du har en bestemt filtype, mens andre kun vil forekomme en gang på hver fil.',
    disableBeacon: true,
  },
  {
    target: '#branch-mutations-edit-mutation',
    title: 'Rediger videoeffekt',
    content: 'Tryk på knappen for at redigere en videoeffekt.',
    disableBeacon: true,
  },
  {
    target: '#branch-mutations-delete-mutation',
    title: 'Slet videoeffekt',
    content: 'Tryk på knappen for at slette en videoeffekt.',
    disableBeacon: true,
  },
  // --- Subtitles --- //
  {
    target: '#subtitles-generate-subtitles',
    title: 'Lav undertekster',
    content:
      'Tryk på knappen, for at starte processen, så du kan lave undertekster til din video. Når du har startet processen, kan du ikke længere ændre i videoen.',
    disableBeacon: true,
  },
  {
    target: '#subtitles-edit-subtitles-area',
    title: 'Rediger undertekster',
    content:
      'Her kan du se og redigere underteksterne til din video. Du kan ændre i dem, så meget du vil, indtil du har godkendt dem.',
    disableBeacon: true,
  },
  {
    target: '#subtitles-preview',
    title: 'Forhåndsvisning af undertekster',
    content:
      'Du kan afspille videoen for at se, hvordan underteksterne ser ud i videoen.',
    disableBeacon: true,
  },
  {
    target: '#subtitles-edit-subtitles',
    title: 'Opdater undertekster',
    content:
      'Tryk på knappen for at opdatere underteksterne til din video. Du kan ændre i dem, så meget du vil, indtil du har godkendt dem.',
    disableBeacon: true,
  },
  {
    target: '#subtitles-approve-subtitles',
    title: 'Godkend undertekster',
    content:
      'Tryk på knappen for at godkende underteksterne til din video. Når du har godkendt dem, kan de ikke længere ændres.',
    disableBeacon: true,
  },
  // --- Result --- //
  {
    target: '#results-finalize',
    title: 'Færddiggør video',
    content:
      'Tryk på knappen for at færdiggøre din video. Når du har færdiggjort din video, kan du ikke længere ændre i den. Sørg for at alt er, som det skal være, før du trykker på knappen.',
    disableBeacon: true,
  },
  {
    target: '#results-overview',
    title: 'Resultater',
    content:
      'Her kan du se resultaterne af din video. Der vil være et resulat for hvert format.',
    disableBeacon: true,
  },
  {
    target: '#results-result',
    title: 'Resultat',
    content: 'Du kan afspille resultatet af videoerne her og downloade dem.',
    disableBeacon: true,
  },
];
