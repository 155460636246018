import { ControlledModal } from './ControlledModal';

interface ConfirmationDialogProps {
  title: string;
  description: string;
  showModal: (state: boolean) => void;
  isOpen: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const ConfirmationDialog = ({
  title,
  description,
  showModal,
  isOpen,
  onConfirm,
  onCancel,
}: ConfirmationDialogProps) => {
  const onConfirmClick = () => {
    onConfirm?.();
    showModal(false);
  };

  const onCancelClick = () => {
    onCancel?.();
    showModal(false);
  };

  return (
    <ControlledModal
      showModal={showModal}
      isOpen={isOpen}
      title={title}
      closeOnOutsideClick
      hideActionBar
    >
      {description}
      <div className="flex justify-center space-x-4 pt-4">
        <button
          data-testid="confirmation-dialog-confirm"
          type="button"
          className="btn btn-primary"
          onClick={onConfirmClick}
        >
          Bekræft
        </button>
        <button type="button" className="btn" onClick={onCancelClick}>
          Annuller
        </button>
      </div>
    </ControlledModal>
  );
};
