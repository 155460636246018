/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EEstateOrbitRenderingMode = {
    WithIndicators: 'RENDERING_WITH_INDICATORS',
    WithoutMasks: 'RENDERING_WITHOUT_MASKS',
    FilledMasks: 'RENDERING_FILLED_MASKS'
} as const;
export type EEstateOrbitRenderingMode = typeof EEstateOrbitRenderingMode[keyof typeof EEstateOrbitRenderingMode];


export function EEstateOrbitRenderingModeFromJSON(json: any): EEstateOrbitRenderingMode {
    return EEstateOrbitRenderingModeFromJSONTyped(json, false);
}

export function EEstateOrbitRenderingModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EEstateOrbitRenderingMode {
    return json as EEstateOrbitRenderingMode;
}

export function EEstateOrbitRenderingModeToJSON(value?: EEstateOrbitRenderingMode | null): any {
    return value as any;
}

