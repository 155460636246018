import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission } from 'api/core';
import { SmartTextCreate } from './SmartTextCreate';
import { SmartTextsTable } from './SmartTextsTable';
import { useGetTextCollections } from 'api/useTextCollectionsApi';

export const SmartTexts = () => {
  const { state } = useTableQueryState();
  const { data, isFetching: isFetchingFields } = useGetTextCollections(state);

  return (
    <Page
      title="Smart Text - Oversigt"
      breadcrumbs={[{ name: 'Smart Text' }, { name: 'Oversigt' }]}
    >
      <div className="flex justify-start py-2 pb-3 w-full">
        <PermissionProtectedComponent
          permissions={[EPermission.TextCollectionWrite]}
        >
          <SmartTextCreate
            trigger={
              <div className="flex justify-start w-full">
                <button className="btn btn-primary mr-2">
                  Opret boligtekst
                </button>
              </div>
            }
          />
        </PermissionProtectedComponent>
      </div>
      <SmartTextsTable
        data={data}
        isFetching={isFetchingFields}
        queryState={state}
      />
    </Page>
  );
};
