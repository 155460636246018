import { ReactNode, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useAuth } from './AuthProvider';
import { FullScreenLoading } from 'components/Loading';

interface AnonymousProtectedRouteProps {
  children: ReactNode;
}

export const AnonymousProtectedRoute = ({
  children,
}: AnonymousProtectedRouteProps) => {
  const { user, isLoading } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();
  const useQuery = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  const returnUrl = useQuery.get('returnUrl');

  useEffect(() => {
    if (!isLoading && user && location.pathname !== '/') {
      navigate(returnUrl || '/');
    }
  }, [user, isLoading, navigate, location, returnUrl]);

  if (isLoading) {
    return <FullScreenLoading />;
  }

  return user ? null : <>{children}</>;
};
