/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EWritingStyle } from './EWritingStyle';
import {
    EWritingStyleFromJSON,
    EWritingStyleFromJSONTyped,
    EWritingStyleToJSON,
} from './EWritingStyle';
import type { EWritingTargetAudience } from './EWritingTargetAudience';
import {
    EWritingTargetAudienceFromJSON,
    EWritingTargetAudienceFromJSONTyped,
    EWritingTargetAudienceToJSON,
} from './EWritingTargetAudience';

/**
 * 
 * @export
 * @interface TextCollectionCreateRequest
 */
export interface TextCollectionCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof TextCollectionCreateRequest
     */
    caseId: string;
    /**
     * 
     * @type {Array<EWritingTargetAudience>}
     * @memberof TextCollectionCreateRequest
     */
    writingTargetAudiences?: Array<EWritingTargetAudience> | null;
    /**
     * 
     * @type {EWritingStyle}
     * @memberof TextCollectionCreateRequest
     */
    writingStyle?: EWritingStyle;
}

/**
 * Check if a given object implements the TextCollectionCreateRequest interface.
 */
export function instanceOfTextCollectionCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "caseId" in value;

    return isInstance;
}

export function TextCollectionCreateRequestFromJSON(json: any): TextCollectionCreateRequest {
    return TextCollectionCreateRequestFromJSONTyped(json, false);
}

export function TextCollectionCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextCollectionCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caseId': json['caseId'],
        'writingTargetAudiences': !exists(json, 'writingTargetAudiences') ? undefined : (json['writingTargetAudiences'] === null ? null : (json['writingTargetAudiences'] as Array<any>).map(EWritingTargetAudienceFromJSON)),
        'writingStyle': !exists(json, 'writingStyle') ? undefined : EWritingStyleFromJSON(json['writingStyle']),
    };
}

export function TextCollectionCreateRequestToJSON(value?: TextCollectionCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caseId': value.caseId,
        'writingTargetAudiences': value.writingTargetAudiences === undefined ? undefined : (value.writingTargetAudiences === null ? null : (value.writingTargetAudiences as Array<any>).map(EWritingTargetAudienceToJSON)),
        'writingStyle': EWritingStyleToJSON(value.writingStyle),
    };
}

