/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CaseCreateRequest,
  CaseMassUpsertRequest,
  CaseResponse,
  CaseResponsePagedData,
  CaseUpdateRequest,
  ECaseStateInt32KeyValueCollection,
} from '../models';
import {
    CaseCreateRequestFromJSON,
    CaseCreateRequestToJSON,
    CaseMassUpsertRequestFromJSON,
    CaseMassUpsertRequestToJSON,
    CaseResponseFromJSON,
    CaseResponseToJSON,
    CaseResponsePagedDataFromJSON,
    CaseResponsePagedDataToJSON,
    CaseUpdateRequestFromJSON,
    CaseUpdateRequestToJSON,
    ECaseStateInt32KeyValueCollectionFromJSON,
    ECaseStateInt32KeyValueCollectionToJSON,
} from '../models';

export interface CasesGetRequest {
    projectId?: string;
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface CasesIdAssetsPutRequest {
    id: string;
    requestBody?: Array<string>;
}

export interface CasesIdDeleteRequest {
    id: string;
}

export interface CasesIdGetRequest {
    id: string;
}

export interface CasesIdPutRequest {
    id: string;
    caseUpdateRequest?: CaseUpdateRequest;
}

export interface CasesProjectIdMassPostRequest {
    projectId: string;
    caseMassUpsertRequest?: CaseMassUpsertRequest;
}

export interface CasesProjectIdPostRequest {
    projectId: string;
    caseCreateRequest?: CaseCreateRequest;
}

export interface CasesSearchGetRequest {
    size?: number;
    searchTerm?: string;
}

export interface CasesStatisticsStatesGetRequest {
    projectId?: string;
}

/**
 * 
 */
export class CasesApi extends runtime.BaseAPI {

    /**
     */
    async casesGetRaw(requestParameters: CasesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CaseResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Cases`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CaseResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async casesGet(requestParameters: CasesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CaseResponsePagedData> {
        const response = await this.casesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async casesIdAssetsPutRaw(requestParameters: CasesIdAssetsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CaseResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling casesIdAssetsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Cases/{id}/assets`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CaseResponseFromJSON(jsonValue));
    }

    /**
     */
    async casesIdAssetsPut(requestParameters: CasesIdAssetsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CaseResponse> {
        const response = await this.casesIdAssetsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async casesIdDeleteRaw(requestParameters: CasesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CaseResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling casesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Cases/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CaseResponseFromJSON(jsonValue));
    }

    /**
     */
    async casesIdDelete(requestParameters: CasesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CaseResponse> {
        const response = await this.casesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async casesIdGetRaw(requestParameters: CasesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CaseResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling casesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Cases/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CaseResponseFromJSON(jsonValue));
    }

    /**
     */
    async casesIdGet(requestParameters: CasesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CaseResponse> {
        const response = await this.casesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async casesIdPutRaw(requestParameters: CasesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CaseResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling casesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Cases/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CaseUpdateRequestToJSON(requestParameters.caseUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CaseResponseFromJSON(jsonValue));
    }

    /**
     */
    async casesIdPut(requestParameters: CasesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CaseResponse> {
        const response = await this.casesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async casesProjectIdMassPostRaw(requestParameters: CasesProjectIdMassPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CaseResponse>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling casesProjectIdMassPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Cases/{projectId}/mass`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CaseMassUpsertRequestToJSON(requestParameters.caseMassUpsertRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CaseResponseFromJSON));
    }

    /**
     */
    async casesProjectIdMassPost(requestParameters: CasesProjectIdMassPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CaseResponse>> {
        const response = await this.casesProjectIdMassPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async casesProjectIdPostRaw(requestParameters: CasesProjectIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CaseResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling casesProjectIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Cases/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CaseCreateRequestToJSON(requestParameters.caseCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CaseResponseFromJSON(jsonValue));
    }

    /**
     */
    async casesProjectIdPost(requestParameters: CasesProjectIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CaseResponse> {
        const response = await this.casesProjectIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async casesSearchGetRaw(requestParameters: CasesSearchGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CaseResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Cases/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CaseResponseFromJSON));
    }

    /**
     */
    async casesSearchGet(requestParameters: CasesSearchGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CaseResponse>> {
        const response = await this.casesSearchGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async casesStatisticsStatesGetRaw(requestParameters: CasesStatisticsStatesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ECaseStateInt32KeyValueCollection>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Cases/statistics/states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ECaseStateInt32KeyValueCollectionFromJSON(jsonValue));
    }

    /**
     */
    async casesStatisticsStatesGet(requestParameters: CasesStatisticsStatesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ECaseStateInt32KeyValueCollection> {
        const response = await this.casesStatisticsStatesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
