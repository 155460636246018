import { MediaSequenceResponse } from 'api/core';
import { BRANCH_TEMPLATE_FORMATS } from './BranchTemplates';
import { useEffect, useState } from 'react';
import {
  useCreateMediaSequenceBranch,
  useDeleteMediaSequenceBranch,
} from 'api/useMediaSequenceBranchesApi';

interface TableProps {
  data?: MediaSequenceResponse;
  isFetching: boolean;
  disabled?: boolean;
}

interface BranchRow {
  height: number;
  width: number;
  name: string;
  recommendedUses: string[];
  isChecked: boolean;
}

export const MediaSequenceBranchSelectionTable = ({
  data,
  disabled,
}: TableProps) => {
  const [rows, setRows] = useState<BranchRow[]>([]);

  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateMediaSequenceBranch();
  const { mutateAsync: deleteAsync, isPending: isPendingDelete } =
    useDeleteMediaSequenceBranch();

  const handleToggleAsync = async (row: BranchRow) => {
    if (row.isChecked) {
      row.isChecked = false;
      const id = data?.branches.find(
        (e) => e.height === row.height && e.width === row.width
      )?.id;
      if (id) {
        await deleteAsync({
          id: id,
        });
      }
    } else {
      row.isChecked = true;
      if (data) {
        await createAsync({
          mediaSequenceId: data?.id ?? '',
          mediaSequenceBranchCreateRequest: {
            height: row.height,
            width: row.width,
            name: row.name,
          },
        });
      }
    }
    setRows([...rows]);
  };

  useEffect(() => {
    const rows: BranchRow[] = BRANCH_TEMPLATE_FORMATS.map((format) => ({
      height: format.height,
      width: format.width,
      name: format.name,
      recommendedUses: format.recommendedUses,
      isChecked: !!data?.branches.some(
        (branch) =>
          branch.height === format.height && branch.width === format.width
      ),
    }));
    setRows(rows);
  }, [data]);

  return (
    <div className="overflow-x-auto">
      <table className="table table-zebra">
        <thead>
          <tr>
            <th>Valgt</th>
            <th>Format</th>
            <th>Anbefalet brug</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                <input
                  id="branches-toggle-branch"
                  type="checkbox"
                  checked={row.isChecked}
                  disabled={disabled || isPendingCreate || isPendingDelete}
                  onChange={async () => await handleToggleAsync(row)}
                  className="toggle toggle-primary"
                />
              </td>
              <td>{row.name}</td>
              <td>{row.recommendedUses.join(', ')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
