import {
  EPermission,
  ETranslationTermNamespace,
  LanguageResponse,
  ProjectTranslationsResponse,
  ProjectTranslationUpdateRequest,
} from 'api/core';
import {
  useGetProject,
  useGetProjectFieldTranslations,
  useGetProjectNamespaceTranslations,
  useUpdateProjectTranslations,
} from 'api/useProjectsApi';
import { useAuth } from 'auth/AuthProvider';
import { Page } from 'layouts/Admin/Page';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { translationTermNamespaceTranslate } from 'utils/enum-translate';

interface Translations {
  languages: LanguageResponse[];
  terms: Term[];
}
interface Term {
  key: string;
  displayName: string;
  namespace: ETranslationTermNamespace;
  projectOverride: boolean;
  languages: {
    language: LanguageResponse;
    originalTranslation: string;
    translation: string;
  }[];
}

const namespaces = [
  ETranslationTermNamespace.CoreField,
  ETranslationTermNamespace.EstateOrbitFrontend,
];

const buildTranslations = (
  fieldTranslationsCollection: (ProjectTranslationsResponse | undefined)[]
): Translations => {
  const translations: Translations = {
    languages: [],
    terms: [],
  };

  // If not all translations are loaded, return empty translation object
  if (!fieldTranslationsCollection?.length) return translations;
  if (fieldTranslationsCollection.some((e) => !e?.languageTranslations?.length))
    return translations;

  // Loop through languages
  fieldTranslationsCollection.forEach((fieldTranslations) => {
    if (!fieldTranslations) return;
    fieldTranslations.languageTranslations.forEach((languageTranslations) => {
      if (
        !translations.languages.some(
          (e) => e.id === languageTranslations.language.id
        )
      ) {
        translations.languages.push(languageTranslations.language);
      }
    });
  });

  // Loop through translations and build terms
  const termMap: { [key: string]: Term } = {};

  fieldTranslationsCollection.forEach((fieldTranslations) => {
    if (!fieldTranslations) return;
    fieldTranslations.languageTranslations.forEach((languageTranslations) => {
      languageTranslations.translations.forEach((translation) => {
        if (!termMap[translation.key]) {
          termMap[translation.key] = {
            key: translation.key,
            displayName: translation.displayName ?? '',
            languages: [],
            namespace: translation.translationTermNamespace,
            projectOverride: !!translation.projectId,
          };
        }

        termMap[translation.key].languages.push({
          language: languageTranslations.language,
          originalTranslation: translation.value,
          translation: translation.value,
        });
      });
    });
  });

  translations.terms = Object.values(termMap);

  return translations;
};

export const ProjectTranslations = () => {
  const { id } = useParams<{ id: string }>();
  const { hasPermissions } = useAuth();

  const { data: project } = useGetProject(id, true);
  const { data: fieldTranslations } = useGetProjectFieldTranslations(id);
  const { data: estateOrbitTranslations } = useGetProjectNamespaceTranslations(
    id,
    ETranslationTermNamespace.EstateOrbitFrontend
  );
  const { mutateAsync: updateAsync } = useUpdateProjectTranslations();

  const [translations, setTranslations] = useState<Translations>({
    languages: [],
    terms: [],
  });

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [termsMissingTranslation, setTermsMissingTranslation] = useState(0);
  const [activeNamespace, setActiveNamespace] = useState(namespaces[0]);

  console.log(fieldTranslations);
  console.log(estateOrbitTranslations);

  useEffect(() => {
    setTranslations(
      buildTranslations([fieldTranslations, estateOrbitTranslations])
    );
  }, [fieldTranslations, estateOrbitTranslations]);

  useEffect(() => {
    const isDirty = translations.terms.some((term) =>
      term.languages.some(
        (language) => language.translation !== language.originalTranslation
      )
    );
    setIsDirty(isDirty);

    const termsMissingTranslation = translations.terms.reduce(
      (acc, term) =>
        acc + term.languages.filter((language) => !language.translation).length,
      0
    );
    setTermsMissingTranslation(termsMissingTranslation);
  }, [translations]);

  if (!fieldTranslations || !estateOrbitTranslations) return null;

  const saveChanges = async () => {
    if (!id) return;
    if (!isDirty) return;

    const dirtyTranslations: ProjectTranslationUpdateRequest[] = [];

    translations.terms.forEach((term) => {
      term.languages.forEach((language) => {
        if (
          language.translation !== language.originalTranslation &&
          language.translation
        ) {
          dirtyTranslations.push({
            translationLanguageId: language.language.id,
            termKey: term.key,
            value: language.translation,
          });
        }
      });
    });

    if (!dirtyTranslations.length) return;

    setIsUpdating(true);
    await updateAsync({
      id,
      projectTranslationsUpdateRequest: {
        translations: dirtyTranslations,
      },
    });
    setIsUpdating(false);
  };

  const hasTranslationTerms = translations.terms.length !== 0;

  return (
    <Page
      title={project?.name}
      breadcrumbs={[
        { name: 'Projekter' },
        { name: project?.name ?? '' },
        { name: 'Oversættelser' },
      ]}
      alert={
        termsMissingTranslation > 0
          ? {
              message: `${termsMissingTranslation} ud af ${
                translations.terms.length * translations.languages.length
              } oversættelser mangler oversættelse`,
              type: 'error',
            }
          : undefined
      }
    >
      {!hasTranslationTerms ? (
        <div>Du har ingen oversættelser til dette projekt</div>
      ) : null}

      {hasTranslationTerms ? (
        <>
          <div className="tabs tabs-bordered">
            {namespaces.map((namespace) => (
              <a
                key={namespace}
                className={twMerge(
                  'tab',
                  activeNamespace === namespace && 'tab-active'
                )}
                onClick={() => setActiveNamespace(namespace)}
              >
                {translationTermNamespaceTranslate(namespace)}
              </a>
            ))}
          </div>
          <div className="flex flex-col gap-4">
            <div className="overflow-x-auto">
              <table className="table table-zebra">
                <thead>
                  <tr>
                    <th>Term</th>
                    {translations.languages.map((language) => (
                      <th key={language.id}>{language.title}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {translations.terms.map((term) => {
                    if (term.namespace !== activeNamespace) return null;
                    return (
                      <tr key={term.key}>
                        <td>{term.displayName}</td>
                        {term.languages.map((language) => (
                          <td key={language.language.id}>
                            <input
                              className={twMerge(
                                'input input-bordered input-sm',
                                !language.translation && 'border-red-500'
                              )}
                              type="text"
                              value={language.translation ?? ''}
                              disabled={
                                isUpdating ||
                                !hasPermissions([EPermission.ProjectWrite])
                              }
                              onChange={(e) => {
                                language.translation = e.target.value;
                                setTranslations({ ...translations });
                              }}
                            />
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="flex justify-center">
              <button
                type="button"
                className="btn btn-primary"
                disabled={!isDirty || isUpdating}
                onClick={saveChanges}
              >
                Gem ændringer
              </button>
            </div>
          </div>
        </>
      ) : null}
    </Page>
  );
};
