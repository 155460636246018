import { useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { useDebounce } from 'utils/useDebounce';
import Select, { SingleValue } from 'react-select';

interface OptionType<T> {
  value: T;
  label?: string;
}

interface EntitySelectSingleAsyncProps<T, SearchParams> {
  id?: string;
  onSuggestionSelected: (suggestion: T | null) => void;
  renderSuggestion: (suggestion: T) => React.ReactNode;
  useSearchFunction: (params: SearchParams) => UseQueryResult<T[], Error>;
  searchFunctionOrder?: (a: T, b: T) => number;
  searchParams: SearchParams;
  initialValue?: T | ((predicate: T) => boolean);
  afterInitialValueSet?: (value: T | null) => void;
  placeholder?: string;
  stickyFirstOption?: OptionType<T>;
  onStickyFirstOptionClicked?: () => void;
  inDialog?: boolean;
  disabled?: boolean;
  required?: boolean;
}

export function EntitySelectSingleAsync<T, SearchParams>({
  id,
  onSuggestionSelected,
  renderSuggestion,
  useSearchFunction,
  searchFunctionOrder,
  searchParams,
  initialValue,
  afterInitialValueSet,
  placeholder = 'Skriv for at søge...',
  stickyFirstOption,
  onStickyFirstOptionClicked,
  inDialog,
  disabled,
  required,
}: EntitySelectSingleAsyncProps<T, SearchParams>) {
  const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement | null>(
    null
  );
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const paramsWithSearchTerm = {
    ...searchParams,
    searchTerm: debouncedSearchTerm,
  };
  const { data: suggestions, isLoading } =
    useSearchFunction(paramsWithSearchTerm);

  const [selectedOption, setSelectedOption] = useState<OptionType<T> | null>(
    initialValue && typeof initialValue != 'function'
      ? { value: initialValue }
      : null
  );
  const [options, setOptions] = useState<OptionType<T>[]>([]);

  useEffect(() => {
    const orderedSuggestions = searchFunctionOrder
      ? suggestions?.sort(searchFunctionOrder)
      : suggestions;

    let formattedOptions =
      orderedSuggestions?.map((suggestion) => ({
        value: suggestion,
      })) ?? [];

    // Insert the sticky option at the top if it exists
    if (stickyFirstOption) {
      formattedOptions = [stickyFirstOption, ...formattedOptions];
    }

    setOptions(formattedOptions);
  }, [suggestions, stickyFirstOption, searchFunctionOrder]);

  useEffect(() => {
    if (inDialog) {
      setMenuPortalTarget(document.querySelector('dialog'));
    } else {
      setMenuPortalTarget(document.querySelector('body'));
    }
  }, [inDialog]);

  const [hasSetInitialValue, setHasSetInitialValue] = useState(false);

  useEffect(() => {
    if (!initialValue || !options || options.length == 0 || hasSetInitialValue)
      return;

    setHasSetInitialValue(true);

    if (initialValue && typeof initialValue !== 'function') {
      setSelectedOption({ value: initialValue, label: '' });
    } else if (initialValue && typeof initialValue === 'function') {
      const initialValueFn: (predicate: T) => boolean = initialValue as (
        predicate: T
      ) => boolean;
      const selectedOption = options.find((option) =>
        initialValueFn(option.value)
      );
      if (selectedOption) {
        setSelectedOption(selectedOption);
      }
      afterInitialValueSet?.(selectedOption?.value ?? null);
    }
  }, [initialValue, options, hasSetInitialValue, afterInitialValueSet]);

  return (
    <div className="form-control w-full" data-testid={id}>
      <Select
        inputId={`${id}-input`}
        options={options}
        value={selectedOption}
        required={required}
        onInputChange={(value) => setSearchTerm(value)}
        onChange={(selectedOption: SingleValue<OptionType<T>>) => {
          if (
            stickyFirstOption &&
            selectedOption?.value === stickyFirstOption.value
          ) {
            onStickyFirstOptionClicked?.();
            return;
          }
          setSelectedOption(selectedOption);
          onSuggestionSelected(selectedOption?.value ?? null);
        }}
        placeholder={placeholder}
        isLoading={isLoading}
        isClearable
        noOptionsMessage={() => 'Ingen resultater fundet'}
        filterOption={null}
        formatOptionLabel={(option) => (
          <>{option.label ? option.label : renderSuggestion(option.value)}</>
        )}
        menuPortalTarget={menuPortalTarget}
        styles={{
          control: (base) => ({
            ...base,
            height: '3rem',
          }),
        }}
        menuPosition="fixed"
        isDisabled={disabled}
      />
    </div>
  );
}
