import { ControlledModal } from 'components/ControlledModal';
import { useEffect, useRef, useState } from 'react';
import {
  useGetTextCollection,
  useUpsertTextEntry,
} from 'api/useTextCollectionsApi';
import { textPurposeTranslate } from 'utils/enum-translate';
import { toast } from 'react-toastify';
import { ETextPurpose, TextEntryResponse } from 'api/core';
import { twMerge } from 'tailwind-merge';

interface SmartTextDetailsProps {
  id?: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
  onCopy?: () => void;
}

export const SmartTextDetails = ({
  id,
  trigger,
  isInitialOpen,
  onClosed,
  onCopy,
}: SmartTextDetailsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);
  const [openIndex, setOpenIndex] = useState<number | null>(0);

  const { data } = useGetTextCollection(id);

  const { mutateAsync: upsertTextEntryAsync } = useUpsertTextEntry();

  if (!data) return null;

  const handleSave = async (entry: TextEntryResponse, newValue: string) => {
    await upsertTextEntryAsync({
      textEntryUpsertRequest: {
        purpose: entry.purpose,
        textCollectionId: data.id,
        value: newValue,
      },
    });
  };

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title={'Smart Text: ' + data._case.address}
        closeOnOutsideClick
        hideActionBar
      >
        <div data-testid="smart-text-result" className="flex flex-col mt-4">
          {data.entries.map((e, i) => {
            return (
              <div className="collapse collapse-arrow bg-base-200" key={e.id}>
                <input
                  type="checkbox"
                  checked={openIndex === i}
                  onChange={() => setOpenIndex(openIndex === i ? null : i)}
                />
                <div className="collapse-title">
                  {textPurposeTranslate(e.purpose)}
                </div>
                <div className="collapse-content">
                  <EditableText
                    value={e.value}
                    onSave={(newValue) => handleSave(e, newValue)}
                    canCopy={true}
                    showCharacterCount={true}
                    maxLength={
                      e.purpose === ETextPurpose.ShopWindow ||
                      e.purpose === ETextPurpose.SocialMedia
                        ? 1000
                        : 3000
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex justify-center space-x-4 pt-4">
          {onCopy ? (
            <button type="button" className="btn btn-primary" onClick={onCopy}>
              Opret ny tekst for denne bolig
            </button>
          ) : null}
          {onClosed ? (
            <button
              data-testid="smart-text-close"
              type="button"
              className="btn"
              onClick={onClosed}
            >
              Annuller
            </button>
          ) : null}
        </div>
      </ControlledModal>
    </>
  );
};

interface EditableTextProps {
  value: string;
  onSave: (newValue: string) => void;
  canCopy?: boolean;
  showCharacterCount?: boolean;
  maxLength?: number;
  autoAdjustHeightToContent?: boolean;
}

// TODO: Could make it just a little bit more generic, and then extract it to its own component
const EditableText = ({
  value,
  onSave,
  maxLength = 2000,
  canCopy = true,
  showCharacterCount = true,
  autoAdjustHeightToContent = true,
}: EditableTextProps) => {
  const [originalValue, setOriginalValue] = useState(value);
  const [text, setText] = useState(value);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [hasChanged, setHasChanged] = useState(false);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 2}px`;
    }
  };

  useEffect(() => {
    if (autoAdjustHeightToContent) {
      adjustTextareaHeight();
    }
    setHasChanged(text !== originalValue);
  }, [text, originalValue, autoAdjustHeightToContent]);

  return (
    <>
      <textarea
        ref={textareaRef}
        className="w-full p-3 bg-gray-100 text-gray-800 rounded-lg border-gray-200 border resize-none"
        value={text}
        onChange={(e) => setText(e.target.value)}
      ></textarea>

      <div className="flex justify-between items-center mb-2">
        {showCharacterCount ? (
          <div
            className={twMerge(
              'text-sm',
              text.length < maxLength ? 'text-gray-500' : 'text-red-500'
            )}
          >
            {text.length} {maxLength ? ` / ${maxLength}` : null}
          </div>
        ) : null}
        {canCopy ? (
          <button
            onClick={() => {
              navigator.clipboard.writeText(text);
              toast.success('Teksten er kopieret til udklipsholderen');
            }}
            className="text-sm text-blue-500 hover:underline"
          >
            Kopier tekst
          </button>
        ) : null}
      </div>

      {hasChanged ? (
        <div className="flex justify-end mt-2">
          <button
            className="btn btn-primary"
            disabled={text.length > maxLength}
            onClick={() => {
              setHasChanged(false);
              setOriginalValue(text);
              onSave(text);
            }}
          >
            Gem
          </button>
        </div>
      ) : null}
    </>
  );
};
