import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useGetMediaSequences } from 'api/useMediaSequencesApi';
import { MediaSequencesTable } from './components/MediaSequence/MediaSequencesTable';
import { MediaSequenceCreate } from './components/MediaSequence/MediaSequenceCreate';
import { useIsMobile } from 'utils/useIsMobile';
import { useAuth } from 'auth/AuthProvider';
import { EPermission } from 'api/core';

export const MediaSequences = () => {
  const isMobile = useIsMobile();
  const { state } = useTableQueryState();
  const { hasPermissions } = useAuth();

  const { data, isFetching: isFetchingFields } = useGetMediaSequences(
    {
      refetchInterval: isMobile ? undefined : 5000,
    },
    state
  );

  return (
    <Page
      title="Smart Video - Videoer"
      breadcrumbs={[{ name: 'Smart Video' }, { name: 'Videoer' }]}
    >
      <div className="flex justify-start py-2 pb-3 w-full">
        <MediaSequenceCreate
          trigger={<button className="btn btn-primary">Opret video</button>}
        />
      </div>
      <MediaSequencesTable
        data={data}
        isFetching={isFetchingFields}
        queryState={state}
        showType={hasPermissions([
          EPermission.MediaSequenceGlobalTemplateAdministrator,
        ])}
        showFolder={hasPermissions([EPermission.FolderRead])}
      />
    </Page>
  );
};
