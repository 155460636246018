/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EWritingStyle } from './EWritingStyle';
import {
    EWritingStyleFromJSON,
    EWritingStyleFromJSONTyped,
    EWritingStyleToJSON,
} from './EWritingStyle';
import type { EWritingTargetAudience } from './EWritingTargetAudience';
import {
    EWritingTargetAudienceFromJSON,
    EWritingTargetAudienceFromJSONTyped,
    EWritingTargetAudienceToJSON,
} from './EWritingTargetAudience';
import type { TextPurposeWithContentLengthRequest } from './TextPurposeWithContentLengthRequest';
import {
    TextPurposeWithContentLengthRequestFromJSON,
    TextPurposeWithContentLengthRequestFromJSONTyped,
    TextPurposeWithContentLengthRequestToJSON,
} from './TextPurposeWithContentLengthRequest';

/**
 * 
 * @export
 * @interface TextCollectionWithAiCreateRequest
 */
export interface TextCollectionWithAiCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof TextCollectionWithAiCreateRequest
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof TextCollectionWithAiCreateRequest
     */
    dawaAddressId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TextCollectionWithAiCreateRequest
     */
    sellingPoints: Array<string>;
    /**
     * 
     * @type {EWritingStyle}
     * @memberof TextCollectionWithAiCreateRequest
     */
    writingStyle: EWritingStyle;
    /**
     * 
     * @type {Array<TextPurposeWithContentLengthRequest>}
     * @memberof TextCollectionWithAiCreateRequest
     */
    purposes: Array<TextPurposeWithContentLengthRequest>;
    /**
     * 
     * @type {Array<EWritingTargetAudience>}
     * @memberof TextCollectionWithAiCreateRequest
     */
    writingTargetAudiences?: Array<EWritingTargetAudience> | null;
}

/**
 * Check if a given object implements the TextCollectionWithAiCreateRequest interface.
 */
export function instanceOfTextCollectionWithAiCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "dawaAddressId" in value;
    isInstance = isInstance && "sellingPoints" in value;
    isInstance = isInstance && "writingStyle" in value;
    isInstance = isInstance && "purposes" in value;

    return isInstance;
}

export function TextCollectionWithAiCreateRequestFromJSON(json: any): TextCollectionWithAiCreateRequest {
    return TextCollectionWithAiCreateRequestFromJSONTyped(json, false);
}

export function TextCollectionWithAiCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextCollectionWithAiCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'dawaAddressId': json['dawaAddressId'],
        'sellingPoints': json['sellingPoints'],
        'writingStyle': EWritingStyleFromJSON(json['writingStyle']),
        'purposes': ((json['purposes'] as Array<any>).map(TextPurposeWithContentLengthRequestFromJSON)),
        'writingTargetAudiences': !exists(json, 'writingTargetAudiences') ? undefined : (json['writingTargetAudiences'] === null ? null : (json['writingTargetAudiences'] as Array<any>).map(EWritingTargetAudienceFromJSON)),
    };
}

export function TextCollectionWithAiCreateRequestToJSON(value?: TextCollectionWithAiCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'dawaAddressId': value.dawaAddressId,
        'sellingPoints': value.sellingPoints,
        'writingStyle': EWritingStyleToJSON(value.writingStyle),
        'purposes': ((value.purposes as Array<any>).map(TextPurposeWithContentLengthRequestToJSON)),
        'writingTargetAudiences': value.writingTargetAudiences === undefined ? undefined : (value.writingTargetAudiences === null ? null : (value.writingTargetAudiences as Array<any>).map(EWritingTargetAudienceToJSON)),
    };
}

