import { Page } from 'layouts/Admin/Page';
import { useNavigate, useParams } from 'react-router';
import {
  useGetMediaSequence,
  useGetValidateMediaSequenceAddDynamicOverlayMutations,
} from 'api/useMediaSequencesApi';
import { createRef, RefObject, useEffect, useMemo, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { EPermission, MediaSequenceResponse } from 'api/core';
import {
  getCurrentProgressStepIndex,
  getSetupSteps,
  MediaSequenceSetupStep,
} from './components/Setup/steps';
import {
  MediaSequenceSetupProvider,
  useMediaSequenceSetup,
} from './components/Setup/MediaSequenceSetupProvider';
import { useAuth } from 'auth/AuthProvider';
import { InlineLoading } from 'components/Loading';
import { toast } from 'react-toastify';
import { isTemplate } from './media-sequence-utils';
import { MEDIA_SEQEUNCE_SET_UP_STEPS } from './components/Setup/media-sequence-set-up-steps';

export const MediaSequenceSetup = () => {
  const { id, step } = useParams<{ id: string; step: string }>();
  const { hasPermissions } = useAuth();
  const navigate = useNavigate();
  const { data: mediaSequence, isLoading } = useGetMediaSequence(
    id,
    {
      retry: 1,
    },
    true
  );

  const { data: isAddDynamicOverlayMutationsValid } =
    useGetValidateMediaSequenceAddDynamicOverlayMutations(id);

  const steps = useMemo(() => {
    return getSetupSteps(mediaSequence).filter((s) =>
      hasPermissions(s.permissions)
    ) as MediaSequenceSetupStep[];
  }, [mediaSequence, hasPermissions]);

  const currentProgressStepIndex = useMemo(() => {
    if (!mediaSequence) return 0;
    return getCurrentProgressStepIndex(
      mediaSequence,
      steps,
      isAddDynamicOverlayMutationsValid ?? false,
      hasPermissions([EPermission.ModuleSocialMedia])
    );
  }, [mediaSequence, steps, hasPermissions, isAddDynamicOverlayMutationsValid]);

  const initialStepIndex =
    step && !isNaN(parseInt(step)) ? parseInt(step) : currentProgressStepIndex;

  if (isLoading) {
    return (
      <InlineLoading
        className="h-full justify-center"
        text="Henter Smart Video"
        icon="loading-icon"
      />
    );
  }

  if (!mediaSequence || steps.length === 0 || currentProgressStepIndex === 0) {
    toast.error('Kunne ikke finde Smart Video');
    navigate('/media-sequences');
    return null;
  }

  return (
    <MediaSequenceSetupProvider
      mediaSequence={mediaSequence}
      currentProgressStepIndex={currentProgressStepIndex}
      initialStepIndex={initialStepIndex}
      steps={steps}
    >
      <MediaSequenceSetupInner mediaSequence={mediaSequence} />
    </MediaSequenceSetupProvider>
  );
};

interface MediaSequenceSetupInnerProps {
  mediaSequence: MediaSequenceResponse;
}

export const MediaSequenceSetupInner = ({
  mediaSequence,
}: MediaSequenceSetupInnerProps) => {
  const { steps, currentStepIndex, setStep, currentProgressStepIndex } =
    useMediaSequenceSetup();

  const stepRefs = useRef<RefObject<HTMLLIElement>[]>(
    steps.map(() => createRef())
  );

  useEffect(() => {
    // Scroll the current step into view when step changes
    if (
      stepRefs.current[currentStepIndex - 1] &&
      stepRefs.current[currentStepIndex - 1].current
    ) {
      stepRefs.current[currentStepIndex - 1].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [currentStepIndex]);

  const onStepClick = (index: number) => {
    if (index > currentProgressStepIndex) return;
    setStep(index);
  };

  const currentStep = steps[currentStepIndex - 1];

  return (
    <Page
      title={mediaSequence.name}
      breadcrumbs={[
        {
          name: isTemplate(mediaSequence)
            ? 'Smart Video Skabelon'
            : 'Smart Video',
        },
        { name: mediaSequence.name },
        { name: 'Opsætning' },
        {
          name: currentStep.title,
        },
      ]}
      alert={currentStep.pageAlert}
      guideSteps={MEDIA_SEQEUNCE_SET_UP_STEPS}
    >
      <div className="flex flex-col pt-2">
        <ul id="step-overview" className="steps steps-horizontal w-full">
          {steps.map((s, index) => (
            <li
              onClick={() => onStepClick(index + 1)}
              id={`step-${index + 1}`}
              key={index}
              ref={stepRefs.current[index]}
              className={twMerge(
                'step !min-w-32 pb-4 md:pb-0',
                index === currentStepIndex - 1 && 'step-info font-bold',
                index < currentProgressStepIndex &&
                  'cursor-pointer step-primary after:!text-white hover:font-bold hover:step-info'
              )}
            >
              <span>{s.title}</span>
            </li>
          ))}
        </ul>
        <div className="divider"></div>
        <div>{currentStep.component}</div>
      </div>
    </Page>
  );
};
