import {
  SectionCreateRequest,
  SectionResponse,
  SectionUpdateRequest,
} from 'api/core';
import {
  useCreateSection,
  useGetSections,
  useUpdateSection,
} from 'api/useSectionsApi';
import { buildQueryStateGetAll } from 'components/Table/useTableQueryState';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface SectionFormProps {
  targetSection?: SectionResponse;
  projectId?: string;
  onSuccess: () => void;
  onCancel: () => void;
}

export const SectionForm = ({
  targetSection,
  projectId,
  onSuccess,
  onCancel,
}: SectionFormProps) => {
  const { data: sections } = useGetSections(
    projectId,
    buildQueryStateGetAll<SectionResponse>()
  );

  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateSection();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateSection();

  const { register, handleSubmit, setValue } = useForm<
    SectionCreateRequest | SectionUpdateRequest
  >({
    defaultValues: targetSection,
  });

  useEffect(() => {
    if (targetSection || !sections) return;
    const currentMaxFieldOrder =
      Math.max(...sections.data.map((f) => f.order)) + 1;
    setValue('order', currentMaxFieldOrder);
  }, [targetSection, sections, setValue]);

  const onSubmit = handleSubmit(async (result) => {
    if (targetSection) {
      await updateAsync({
        id: targetSection.id,
        sectionUpdateRequest: result,
      });
    } else {
      if (!projectId) return;
      await createAsync({
        projectId,
        sectionCreateRequest: result,
      });
    }
    onSuccess();
  });

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Navn</span>
        </label>
        <input
          {...register('name', { required: true })}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Maske ID</span>
        </label>
        <input
          {...register('maskId', { required: false })}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Rækkefølge</span>
        </label>
        <input
          {...register('order', {
            required: true,
          })}
          type="number"
          min={0}
          className="input input-bordered"
        />
      </div>

      <div className="flex justify-center space-x-4 pt-4">
        <button
          className="btn btn-primary"
          disabled={isPendingCreate || isPendingUpdate}
        >
          {targetSection ? 'Opdater' : 'Opret'}
        </button>
        {onCancel ? (
          <button type="button" className="btn" onClick={onCancel}>
            Annuller
          </button>
        ) : null}
      </div>
    </form>
  );
};
