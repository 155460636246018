/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AudioAdjustmentsResponse
 */
export interface AudioAdjustmentsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AudioAdjustmentsResponse
     */
    normalizeAudio?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AudioAdjustmentsResponse
     */
    removeNoise?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AudioAdjustmentsResponse
     */
    volume?: number | null;
}

/**
 * Check if a given object implements the AudioAdjustmentsResponse interface.
 */
export function instanceOfAudioAdjustmentsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AudioAdjustmentsResponseFromJSON(json: any): AudioAdjustmentsResponse {
    return AudioAdjustmentsResponseFromJSONTyped(json, false);
}

export function AudioAdjustmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AudioAdjustmentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'normalizeAudio': !exists(json, 'normalizeAudio') ? undefined : json['normalizeAudio'],
        'removeNoise': !exists(json, 'removeNoise') ? undefined : json['removeNoise'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
    };
}

export function AudioAdjustmentsResponseToJSON(value?: AudioAdjustmentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'normalizeAudio': value.normalizeAudio,
        'removeNoise': value.removeNoise,
        'volume': value.volume,
    };
}

