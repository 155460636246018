import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

const analyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

export const analyticsEvent = (event: UaEventOptions) => {
  if (analyticsId) {
    ReactGA.event(event);
  }
};
