/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrganizationAuthResponsePagedData,
  OrganizationCreateRequest,
  OrganizationImportRequest,
  OrganizationResponse,
  OrganizationResponsePagedData,
  OrganizationUpdateRequest,
  PublicOrganizationInformation,
} from '../models';
import {
    OrganizationAuthResponsePagedDataFromJSON,
    OrganizationAuthResponsePagedDataToJSON,
    OrganizationCreateRequestFromJSON,
    OrganizationCreateRequestToJSON,
    OrganizationImportRequestFromJSON,
    OrganizationImportRequestToJSON,
    OrganizationResponseFromJSON,
    OrganizationResponseToJSON,
    OrganizationResponsePagedDataFromJSON,
    OrganizationResponsePagedDataToJSON,
    OrganizationUpdateRequestFromJSON,
    OrganizationUpdateRequestToJSON,
    PublicOrganizationInformationFromJSON,
    PublicOrganizationInformationToJSON,
} from '../models';

export interface OrganizationsGetRequest {
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface OrganizationsIdDeleteRequest {
    id: string;
}

export interface OrganizationsIdGetRequest {
    id: string;
}

export interface OrganizationsIdPutRequest {
    id: string;
    organizationUpdateRequest?: OrganizationUpdateRequest;
}

export interface OrganizationsImportPostRequest {
    organizationImportRequest?: OrganizationImportRequest;
}

export interface OrganizationsJoinableGetRequest {
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface OrganizationsPostRequest {
    organizationCreateRequest?: OrganizationCreateRequest;
}

export interface OrganizationsPublicInformationGetRequest {
    query: string;
}

/**
 * 
 */
export class OrganizationsApi extends runtime.BaseAPI {

    /**
     */
    async organizationsGetRaw(requestParameters: OrganizationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async organizationsGet(requestParameters: OrganizationsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationResponsePagedData> {
        const response = await this.organizationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsIdDeleteRaw(requestParameters: OrganizationsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsIdDelete(requestParameters: OrganizationsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationResponse> {
        const response = await this.organizationsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsIdGetRaw(requestParameters: OrganizationsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsIdGet(requestParameters: OrganizationsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationResponse> {
        const response = await this.organizationsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsIdPutRaw(requestParameters: OrganizationsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationUpdateRequestToJSON(requestParameters.organizationUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsIdPut(requestParameters: OrganizationsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationResponse> {
        const response = await this.organizationsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsImportPostRaw(requestParameters: OrganizationsImportPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Organizations/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationImportRequestToJSON(requestParameters.organizationImportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsImportPost(requestParameters: OrganizationsImportPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationResponse> {
        const response = await this.organizationsImportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsJoinableGetRaw(requestParameters: OrganizationsJoinableGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationAuthResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Organizations/joinable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationAuthResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async organizationsJoinableGet(requestParameters: OrganizationsJoinableGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationAuthResponsePagedData> {
        const response = await this.organizationsJoinableGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsPostRaw(requestParameters: OrganizationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationCreateRequestToJSON(requestParameters.organizationCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsPost(requestParameters: OrganizationsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationResponse> {
        const response = await this.organizationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsPublicInformationGetRaw(requestParameters: OrganizationsPublicInformationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicOrganizationInformation>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling organizationsPublicInformationGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Organizations/PublicInformation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicOrganizationInformationFromJSON(jsonValue));
    }

    /**
     */
    async organizationsPublicInformationGet(requestParameters: OrganizationsPublicInformationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicOrganizationInformation> {
        const response = await this.organizationsPublicInformationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
