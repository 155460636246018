import { MediaSequenceResponse } from 'api/core';
import { getAvailableBranchMutations } from 'utils/available-mutations';
import { MediaSequenceBranchMutationCreate } from 'pages/MediaOrbit/components/MediaSequenceBranchMutation/MediaSequenceBranchMutationCreate';
import { MediaSequenceBranchMutationsTable } from 'pages/MediaOrbit/components/MediaSequenceBranchMutation/MediaSequenceBranchMutationsTable';
import { useMediaSequenceSetup } from './MediaSequenceSetupProvider';

interface MediaSequenceSetupBranchMutationsProps {
  mediaSequence: MediaSequenceResponse;
  disabled: boolean;
}

export const MediaSequenceSetupBranchMutations = ({
  mediaSequence,
  disabled,
}: MediaSequenceSetupBranchMutationsProps) => {
  const { handlePrevious, handleNext } = useMediaSequenceSetup();

  return (
    <>
      <div className="grid flex-grow h-full">
        <MediaSequenceBranchMutationCreate
          mediaSequence={mediaSequence}
          trigger={
            <div className="flex justify-start py-4 w-full">
              <button
                id="branch-mutations-add-mutation"
                className="btn btn-primary mr-2"
              >
                Tilføj effekt
              </button>
            </div>
          }
          disabled={
            getAvailableBranchMutations(mediaSequence).length === 0 || disabled
          }
        />
        <div id="branch-mutations-overview">
          <MediaSequenceBranchMutationsTable
            mediaSequence={mediaSequence}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="flex flex-col mt-4 mb-8">
        <div id="step-navigation" className="join justify-center mt-2">
          <button className="join-item btn" onClick={handlePrevious}>
            Forrige trin
          </button>
          <button
            data-testid="media-sequence-next-step"
            className="join-item btn"
            onClick={handleNext}
          >
            Næste trin
          </button>
        </div>
      </div>
    </>
  );
};
