import { useMutation, useQuery } from '@tanstack/react-query';
import { TextEntryUpsertRequest } from './core/models/TextEntryUpsertRequest';

import { TableQueryState } from 'components/Table/useTableQueryState';
import { queryClient } from 'query-client';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  TextCollectionCreateRequest,
  TextCollectionsApi,
  TextCollectionWithAiCreateRequest,
} from './core';

export const TextCollectionApiKeys = {
  GET_TEXT_COLLECTIONS: 'textCollections',
  GET_TEXT_COLLECTION: 'textCollection',
};

const client = new TextCollectionsApi(new CoreBaseConfiguration());

export const useGetTextCollections = (state?: TableQueryState) => {
  return useQuery({
    queryKey: [TextCollectionApiKeys.GET_TEXT_COLLECTIONS, state],
    queryFn: () =>
      client.textCollectionsGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
        showArchived: state?.showArchived,
      }),
  });
};

export const useGetTextCollection = (id?: string) => {
  return useQuery({
    queryKey: [TextCollectionApiKeys.GET_TEXT_COLLECTION, id],
    queryFn: async () => await client.textCollectionsIdGet({ id: id ?? '' }),
    enabled: id !== undefined,
  });
};
export const useCreateTextCollection = () => {
  return useMutation({
    mutationFn: ({
      textCollectionCreateRequest,
    }: {
      textCollectionCreateRequest: TextCollectionCreateRequest;
    }) => client.textCollectionsPost({ textCollectionCreateRequest }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TextCollectionApiKeys.GET_TEXT_COLLECTIONS],
      });
      toast.success(`Smart Tekst er blevet oprettet`, {
        toastId: 'createTextCollection',
      });
    },
  });
};

export const useCreateTextEntriesUsingAi = () => {
  return useMutation({
    mutationFn: ({
      textCollectionWithAiCreateRequest,
    }: {
      textCollectionWithAiCreateRequest: TextCollectionWithAiCreateRequest;
    }) =>
      client.textCollectionsCreateUsingAiPost({
        textCollectionWithAiCreateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [TextCollectionApiKeys.GET_TEXT_COLLECTIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [TextCollectionApiKeys.GET_TEXT_COLLECTION, e.id],
      });
      toast.success(`Boligtekster er blevet genereret`, {
        toastId: 'aiTextCollection',
      });
    },
  });
};

export const useDeleteTextCollection = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.textCollectionsIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [TextCollectionApiKeys.GET_TEXT_COLLECTIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [TextCollectionApiKeys.GET_TEXT_COLLECTION, e.id],
      });
      toast.success(`Smart Teksten er blevet arkiveret`, {
        toastId: 'deleteTextCollection',
      });
    },
  });
};

export const useUpsertTextEntry = () => {
  return useMutation({
    mutationFn: ({
      textEntryUpsertRequest,
    }: {
      textEntryUpsertRequest: TextEntryUpsertRequest;
    }) =>
      client.textCollectionsPut({
        textEntryUpsertRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [TextCollectionApiKeys.GET_TEXT_COLLECTIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [TextCollectionApiKeys.GET_TEXT_COLLECTION, e.id],
      });
      toast.success(`Boligtekst er blevet opdateret`, {
        toastId: 'upsertTextEntry',
      });
    },
  });
};
