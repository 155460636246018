import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { Outlet } from 'react-router';
import { useDocumentTitle } from 'utils/useDocumentTitle';

export const DemoLayout = () => {
  useDocumentTitle('Demo');
  return (
    <>
      <div className="navbar bg-base-200 shadow-sm flex justify-center md:justify-start">
        <img src="/logo-icon.svg" className="h-16 mr-3" alt="Smart Create" />
        <span className="self-center text-primary font-semibold text-2xl whitespace-nowrap block">
          Smart Create Demo
        </span>
      </div>

      <Outlet />
      <footer className="footer sm:footer-horizontal bg-base-200 text-neutral-content items-center p-4 gap-2">
        <aside className="grid-flow-col items-center place-self-center md:place-self-start">
          <img src="/logo-icon.svg" className="h-12 mr-3" alt="Smart Create" />
          <p className="text-primary">
            Copyright © {new Date().getFullYear()} - Alle rettigheder
            forbeholdes
          </p>
        </aside>
        <nav className="grid-flow-col gap-4 place-self-center md:justify-self-end">
          <a
            className="btn btn-ghost text-primary"
            href="mailto:kontakt@smartcreate.dk"
          >
            Kontakt os
            <AnimatedIcon icon="mail-plus-icon" className="h-10 w-10" />
          </a>
        </nav>
      </footer>
    </>
  );
};
