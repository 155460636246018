/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FolderCreateRequest,
  FolderResponse,
  FolderResponsePagedData,
  FolderUpdateRequest,
} from '../models';
import {
    FolderCreateRequestFromJSON,
    FolderCreateRequestToJSON,
    FolderResponseFromJSON,
    FolderResponseToJSON,
    FolderResponsePagedDataFromJSON,
    FolderResponsePagedDataToJSON,
    FolderUpdateRequestFromJSON,
    FolderUpdateRequestToJSON,
} from '../models';

export interface FoldersGetRequest {
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface FoldersIdDeleteRequest {
    id: string;
}

export interface FoldersIdGetRequest {
    id: string;
}

export interface FoldersIdPutRequest {
    id: string;
    folderUpdateRequest?: FolderUpdateRequest;
}

export interface FoldersPostRequest {
    folderCreateRequest?: FolderCreateRequest;
}

export interface FoldersSearchGetRequest {
    size?: number;
    searchTerm?: string;
}

/**
 * 
 */
export class FoldersApi extends runtime.BaseAPI {

    /**
     */
    async foldersGetRaw(requestParameters: FoldersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FolderResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Folders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FolderResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async foldersGet(requestParameters: FoldersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FolderResponsePagedData> {
        const response = await this.foldersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async foldersIdDeleteRaw(requestParameters: FoldersIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FolderResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling foldersIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Folders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FolderResponseFromJSON(jsonValue));
    }

    /**
     */
    async foldersIdDelete(requestParameters: FoldersIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FolderResponse> {
        const response = await this.foldersIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async foldersIdGetRaw(requestParameters: FoldersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FolderResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling foldersIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Folders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FolderResponseFromJSON(jsonValue));
    }

    /**
     */
    async foldersIdGet(requestParameters: FoldersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FolderResponse> {
        const response = await this.foldersIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async foldersIdPutRaw(requestParameters: FoldersIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FolderResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling foldersIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Folders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FolderUpdateRequestToJSON(requestParameters.folderUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FolderResponseFromJSON(jsonValue));
    }

    /**
     */
    async foldersIdPut(requestParameters: FoldersIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FolderResponse> {
        const response = await this.foldersIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async foldersPostRaw(requestParameters: FoldersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FolderResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Folders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FolderCreateRequestToJSON(requestParameters.folderCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FolderResponseFromJSON(jsonValue));
    }

    /**
     */
    async foldersPost(requestParameters: FoldersPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FolderResponse> {
        const response = await this.foldersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async foldersSearchGetRaw(requestParameters: FoldersSearchGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FolderResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Folders/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FolderResponseFromJSON));
    }

    /**
     */
    async foldersSearchGet(requestParameters: FoldersSearchGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FolderResponse>> {
        const response = await this.foldersSearchGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
