import { AssetSimpleResponse, EAssetType } from 'api/core';
import { EntitySelectSingleAsync } from './EntitySelectSingleAsync';
import { useSearchAssets } from 'api/useAssetsApi';
import { AssetPreviewCell } from 'components/Table/Cell/AssetPreviewCell';

interface AssetSelectSingleAsyncProps {
  id?: string;
  projectId?: string;
  types: EAssetType[];
  onSelected: (asset: AssetSimpleResponse | null) => void;
  initialValue?: AssetSimpleResponse;
  showCreateOption?: boolean;
  onCreateOptionSelected?: () => void;
  inDialog?: boolean;
  disabled?: boolean;
}

export const AssetSelectSingleAsync = ({
  id,
  projectId,
  types,
  onSelected,
  initialValue,
  showCreateOption,
  onCreateOptionSelected,
  inDialog,
  disabled,
}: AssetSelectSingleAsyncProps) => {
  return (
    <>
      <EntitySelectSingleAsync<
        AssetSimpleResponse | null,
        { searchTerm?: string; types?: EAssetType[]; projectId?: string }
      >
        id={id}
        useSearchFunction={useSearchAssets}
        searchParams={{ types, projectId }}
        renderSuggestion={(asset) =>
          asset ? (
            <div
              className="flex items-center justify-start space-x-2"
              data-testid={`${id}-${asset.id}`}
            >
              <AssetPreviewCell asset={asset} />
              <p>{asset.originalFileName}</p>
            </div>
          ) : null
        }
        onSuggestionSelected={onSelected}
        initialValue={initialValue}
        stickyFirstOption={
          showCreateOption
            ? {
                value: null,
                label: 'Upload ny fil',
              }
            : undefined
        }
        onStickyFirstOptionClicked={onCreateOptionSelected}
        inDialog={inDialog}
        disabled={disabled}
      />
      {false && (
        <div className="label">
          {projectId != null ? (
            <span className="label-text-alt">
              Du ser kun filer fra det projekt som din tilknyttede sag ligger
              på, da du har tilknyttet en sag til videoen.
            </span>
          ) : (
            <span className="label-text-alt">
              Du ser filer fra alle de projekter du har adgang til da du ikke
              har tilknyttet nogen sag til videoen.
            </span>
          )}
        </div>
      )}
    </>
  );
};
