/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CaseFieldResponse } from './CaseFieldResponse';
import {
    CaseFieldResponseFromJSON,
    CaseFieldResponseFromJSONTyped,
    CaseFieldResponseToJSON,
} from './CaseFieldResponse';
import type { ECaseState } from './ECaseState';
import {
    ECaseStateFromJSON,
    ECaseStateFromJSONTyped,
    ECaseStateToJSON,
} from './ECaseState';

/**
 * 
 * @export
 * @interface CaseCreateRequest
 */
export interface CaseCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof CaseCreateRequest
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CaseCreateRequest
     */
    dawaAddressId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseCreateRequest
     */
    maskId?: string | null;
    /**
     * 
     * @type {ECaseState}
     * @memberof CaseCreateRequest
     */
    state: ECaseState;
    /**
     * 
     * @type {Array<CaseFieldResponse>}
     * @memberof CaseCreateRequest
     */
    caseFields: Array<CaseFieldResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CaseCreateRequest
     */
    assetIds: Array<string>;
}

/**
 * Check if a given object implements the CaseCreateRequest interface.
 */
export function instanceOfCaseCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "caseFields" in value;
    isInstance = isInstance && "assetIds" in value;

    return isInstance;
}

export function CaseCreateRequestFromJSON(json: any): CaseCreateRequest {
    return CaseCreateRequestFromJSONTyped(json, false);
}

export function CaseCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaseCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'dawaAddressId': !exists(json, 'dawaAddressId') ? undefined : json['dawaAddressId'],
        'maskId': !exists(json, 'maskId') ? undefined : json['maskId'],
        'state': ECaseStateFromJSON(json['state']),
        'caseFields': ((json['caseFields'] as Array<any>).map(CaseFieldResponseFromJSON)),
        'assetIds': json['assetIds'],
    };
}

export function CaseCreateRequestToJSON(value?: CaseCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'dawaAddressId': value.dawaAddressId,
        'maskId': value.maskId,
        'state': ECaseStateToJSON(value.state),
        'caseFields': ((value.caseFields as Array<any>).map(CaseFieldResponseToJSON)),
        'assetIds': value.assetIds,
    };
}

