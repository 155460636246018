import ReactDOM from 'react-dom';
import { AnimatedIcon, AnimatedIconKey } from './Icon/AnimatedIcon';
import { twMerge } from 'tailwind-merge';
import { ReactNode } from 'react';

export const InlineLoading = ({
  text,
  icon,
  className,
}: {
  text: string;
  icon: AnimatedIconKey;
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        'flex flex-col items-center gap-2 mt-4 opacity-60 select-none',
        className
      )}
    >
      <AnimatedIcon
        icon={icon}
        autoPlay={true}
        loop={true}
        className="h-24 w-24 size-24"
      />
      <b>{text}</b>
    </div>
  );
};

export const FullScreenLoading = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
      <span className="loading loading-spinner loading-lg text-primary"></span>
    </div>
  );
};

export const FullscreenLoadingWithText = ({
  text,
  icon,
}: {
  text: string;
  icon: AnimatedIconKey;
}) => {
  const loadingContent = (
    <div
      className="fixed top-0 right-0 bottom-0 left-0 bg-black bg-opacity-50 text-white flex items-center justify-center text-lg"
      style={{ zIndex: 999 }}
    >
      <div className="flex flex-col items-center">
        <AnimatedIcon icon={icon} autoPlay loop className="h-64 w-64" />
        <h2 className="text-2xl">{text}</h2>
      </div>
    </div>
  );

  const loadingRootElement = document.getElementById('loading-root');
  if (!loadingRootElement) return null;

  return ReactDOM.createPortal(loadingContent, loadingRootElement);
};

export const FullscreenLoadingWithComponent = ({
  children,
}: {
  children: ReactNode;
}) => {
  const loadingContent = (
    <div
      className="fixed top-0 right-0 bottom-0 left-0 bg-black bg-opacity-50 text-white flex items-center justify-center text-lg"
      style={{ zIndex: 999 }}
    >
      <>{children}</>
    </div>
  );

  const loadingRootElement = document.getElementById('loading-root');
  if (!loadingRootElement) return null;

  return ReactDOM.createPortal(loadingContent, loadingRootElement);
};
