import { AssetSimpleResponse, EAssetType } from 'api/core';
import './AudioPreview.css';
import { RefObject, useEffect, useRef, useState } from 'react';

interface AudioPreviewProps {
  asset?: AssetSimpleResponse;
  src?: string;
  volume: number;
  className?: string | undefined;
  ref?: React.RefObject<HTMLAudioElement>;
}

export const AudioPreview = ({
  asset,
  className,
  volume,
  src,
  ref,
}: AudioPreviewProps) => {
  const ownAudioRef = useRef<HTMLAudioElement>(null);
  const [audioRef, setAudioRef] = useState<RefObject<HTMLAudioElement>>();

  useEffect(() => {
    setAudioRef(ref || ownAudioRef);
  }, [ref, ownAudioRef]);

  useEffect(() => {
    if (audioRef && audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [asset, volume, audioRef]);

  if (asset && asset.type !== EAssetType.Audio) {
    console.warn('Asset provided is not an audio asset');
    return;
  }

  if (!asset && !src) {
    console.warn('No audio asset or src provided');
    return;
  }

  return (
    <audio
      id="audio-preview"
      key={asset?.id || src}
      className={className}
      crossOrigin="anonymous"
      controls
      ref={audioRef}
      onPlay={() => {
        if (audioRef?.current) {
          audioRef.current.volume = volume;
        }
      }}
    >
      <source src={asset?.url || src} />
      Your browser does not support the audio element.
    </audio>
  );
};
