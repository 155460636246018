import { Link } from 'components/Typography/Link';
import { InfoIcon } from 'lucide-react';
import {
  createRef,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDocumentTitle } from 'utils/useDocumentTitle';
import { twMerge } from 'tailwind-merge';
import Joyride, { Step } from 'react-joyride';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { Tooltip } from 'components/Tooltip';
import { analyticsEvent } from 'utils/google-analytics';

interface Breadcrumb {
  name: string;
  to?: string;
}

export interface PageAlert {
  header?: string;
  message: string;
  type?: 'info' | 'success' | 'warning' | 'error';
  icon?: ReactNode;
}

interface PageProps {
  id?: string;
  title?: string;
  description?: string;
  breadcrumbs?: Breadcrumb[];
  alert?: PageAlert;
  guideSteps?: Step[];
  children: ReactNode;
}

const GUIDE_DISMISSED_KEY = 'estate-engine-guide-dismissed';

export const Page = ({
  id,
  title,
  description,
  breadcrumbs,
  alert,
  guideSteps,
  children,
}: PageProps) => {
  useDocumentTitle(title);
  const [hasDismissedInitialGuide, setHasDismissedInitialGuide] = useState(
    localStorage.getItem(GUIDE_DISMISSED_KEY) !== null
  );
  const [joyrideSteps, setJoyrideSteps] = useState<Step[]>([]);
  const [runGuide, setRunGuide] = useState(false);

  const hasGuide = guideSteps && guideSteps.length > 0;

  const breadcrumbRefs = useRef<RefObject<HTMLLIElement>[]>(
    breadcrumbs ? breadcrumbs.map(() => createRef()) : []
  );

  const onRunGuide = () => {
    if (!hasGuide) return;
    analyticsEvent({
      category: 'guide',
      label: 'guide_start_label',
      action: 'guide_start',
    });
    setRunGuide(true);
  };

  useEffect(() => {
    if (breadcrumbRefs.current.length > 0) {
      const lastBreadcrumb =
        breadcrumbRefs.current[breadcrumbRefs.current.length - 1].current;
      if (lastBreadcrumb) {
        lastBreadcrumb.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [breadcrumbs]);

  useEffect(() => {
    if (!hasDismissedInitialGuide && hasGuide) {
      // If guide has never been dismissed and guide steps are available
      setJoyrideSteps([
        {
          target: '#start-guide',
          placement: 'right',
          disableBeacon: true,
          title: 'Guide',
          content:
            'Tryk på ikonet for at starte guiden. Denne besked vises kun én gang.',
        },
      ]);
      setRunGuide(true);
      // Update local storage to indicate the guide has been dismissed
      localStorage.setItem(GUIDE_DISMISSED_KEY, 'true');
    } else if (hasDismissedInitialGuide && hasGuide) {
      // If guide has been dismissed before and there are guide steps
      setJoyrideSteps(guideSteps);
    }
  }, [hasDismissedInitialGuide, hasGuide, guideSteps]);

  return (
    <div id={id}>
      <Joyride
        continuous
        run={runGuide}
        steps={joyrideSteps}
        locale={{
          back: 'Tilbage',
          close: 'Luk',
          last: 'Færdig',
          next: 'Næste',
          skip: 'Spring over',
        }}
        styles={{
          options: {
            primaryColor: 'var(--color-primary)',
          },
        }}
        callback={({ status, action }) => {
          if (
            status === 'finished' ||
            status === 'skipped' ||
            action === 'close'
          ) {
            if (!hasDismissedInitialGuide) {
              setHasDismissedInitialGuide(true);
            }
            setRunGuide(false);
          }
        }}
      />
      {alert ? (
        <div
          role="alert"
          className={twMerge(
            'alert shadow-none text-white rounded-none md:rounded-tl-xl',
            (alert.type === 'info' || !alert.type) && 'alert-info',
            alert.type === 'success' && 'alert-success',
            alert.type === 'warning' && 'alert-warning',
            alert.type === 'error' && 'alert-error'
          )}
        >
          <div className="hidden md:flex">
            {alert.icon ? alert.icon : <InfoIcon size={32} />}
          </div>
          <div>
            <h3 className="font-bold">{alert?.header ?? 'Vær opmærksom'}</h3>
            <span>{alert.message}</span>
          </div>
        </div>
      ) : null}
      <div className="px-6 py-4">
        <div className="flex space-x-4">
          {hasGuide ? (
            <Tooltip position="right" tooltip="Start guide for siden">
              <button id="start-guide" type="button" onClick={onRunGuide}>
                <AnimatedIcon icon="help-icon" className="h-10 w-10" />
              </button>
            </Tooltip>
          ) : null}
          <div className="text-lg breadcrumbs">
            <ul>
              {breadcrumbs ? (
                breadcrumbs.map((e, i) => {
                  const isLast = i === breadcrumbs.length - 1;

                  const className = twMerge(
                    'text-gray-500',
                    isLast && 'text-black font-semibold'
                  );

                  return e.to ? (
                    <li
                      key={i}
                      className={className}
                      ref={breadcrumbRefs.current[i]}
                    >
                      <Link to={e.to}>
                        <span id={isLast ? 'initial-step' : undefined}>
                          {e.name}
                        </span>
                      </Link>
                    </li>
                  ) : (
                    <li
                      key={i}
                      className={className}
                      ref={breadcrumbRefs.current[i]}
                    >
                      <span id={isLast ? 'initial-step' : undefined}>
                        {e.name}
                      </span>
                    </li>
                  );
                })
              ) : title ? (
                <li id="initial-step" className="text-black font-semibold">
                  {title}
                </li>
              ) : null}
            </ul>
          </div>
        </div>
        {description ? (
          <div>
            <p>{description}</p>
          </div>
        ) : null}
        <div className="pb-20 md:pb-0">{children}</div>
      </div>
    </div>
  );
};
