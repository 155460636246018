import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';

import { Keys } from 'react-hook-form/dist/types/path/common';
import { TableQueryState } from './useTableQueryState';

declare module '@tanstack/react-table' {
  // TODO: Fix this...
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends unknown = unknown> {
    queryState?: TableQueryState;
  }
}

export const useGetCustomReactTable = <T>(
  data: T[],
  pageCount: number,
  columns: ColumnDef<T>[],
  queryState?: TableQueryState,
  otherState: Exclude<TableOptions<T>['state'], Keys<TableQueryState>> = {}
) => {
  return useReactTable({
    data,
    pageCount,
    columns,
    state: {
      ...otherState,
      pagination: queryState?.pagination,
      sorting: queryState?.sorting,
      globalFilter: queryState?.globalFilter,
    },
    onPaginationChange: queryState?.setPagination,
    onSortingChange: queryState?.setSorting,
    onGlobalFilterChange: queryState?.setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting: true,
    enableMultiSort: false,
    meta: {
      queryState,
    },
  });
};
