import {
  AssetSimpleResponse,
  EImageToVideoMode,
  MediaSequenceAssetMutationCreateRequest,
  MediaSequenceAssetMutationUpdateRequest,
} from 'api/core';
import { ImageToVideoPreview } from 'components/Image/ImageToVideoPreview';
import { useFormContext } from 'react-hook-form';
import { imageToVideoModeTranslate } from 'utils/enum-translate';

interface ImageToVideoProps {
  asset: AssetSimpleResponse;
}

export const ImageToVideo = ({ asset }: ImageToVideoProps) => {
  const { register, watch } = useFormContext<
    | MediaSequenceAssetMutationCreateRequest
    | MediaSequenceAssetMutationUpdateRequest
  >();

  return (
    <>
      <div className="form-control">
        <label className="label">
          <span className="label-text">Animation</span>
        </label>
        <select
          {...register('imageToVideo.mode', { required: true })}
          className="select select-bordered"
        >
          {[
            EImageToVideoMode.Automatic,
            EImageToVideoMode.PanLeftToRight,
            EImageToVideoMode.ZoomIn,
            EImageToVideoMode.ZoomOut,
            EImageToVideoMode.PanTopLeftToBottomRight,
            EImageToVideoMode.Still,
            // EImageToVideoMode.KenBurns,
          ].map((mode) => (
            <option key={mode} value={mode}>
              {imageToVideoModeTranslate(mode)}
            </option>
          ))}
        </select>
      </div>
      <ImageToVideoPreview asset={asset} mode={watch('imageToVideo.mode')} />

      <div className="form-control">
        <label className="label">
          <span className="label-text">Varighed i sekunder</span>
        </label>
        <input
          {...register('imageToVideo.durationInSeconds', {
            required: true,
          })}
          type="number"
          min={0}
          max={30}
          className="input input-bordered"
        />
      </div>
    </>
  );
};
