/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CaseResponse } from './CaseResponse';
import {
    CaseResponseFromJSON,
    CaseResponseFromJSONTyped,
    CaseResponseToJSON,
} from './CaseResponse';
import type { EEntityState } from './EEntityState';
import {
    EEntityStateFromJSON,
    EEntityStateFromJSONTyped,
    EEntityStateToJSON,
} from './EEntityState';
import type { EWritingStyle } from './EWritingStyle';
import {
    EWritingStyleFromJSON,
    EWritingStyleFromJSONTyped,
    EWritingStyleToJSON,
} from './EWritingStyle';
import type { EWritingTargetAudience } from './EWritingTargetAudience';
import {
    EWritingTargetAudienceFromJSON,
    EWritingTargetAudienceFromJSONTyped,
    EWritingTargetAudienceToJSON,
} from './EWritingTargetAudience';
import type { SellingPointResponse } from './SellingPointResponse';
import {
    SellingPointResponseFromJSON,
    SellingPointResponseFromJSONTyped,
    SellingPointResponseToJSON,
} from './SellingPointResponse';
import type { TextEntryResponse } from './TextEntryResponse';
import {
    TextEntryResponseFromJSON,
    TextEntryResponseFromJSONTyped,
    TextEntryResponseToJSON,
} from './TextEntryResponse';
import type { UserSimpleResponse } from './UserSimpleResponse';
import {
    UserSimpleResponseFromJSON,
    UserSimpleResponseFromJSONTyped,
    UserSimpleResponseToJSON,
} from './UserSimpleResponse';

/**
 * 
 * @export
 * @interface TextCollectionResponse
 */
export interface TextCollectionResponse {
    /**
     * 
     * @type {Date}
     * @memberof TextCollectionResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof TextCollectionResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof TextCollectionResponse
     */
    id: string;
    /**
     * 
     * @type {CaseResponse}
     * @memberof TextCollectionResponse
     */
    _case: CaseResponse;
    /**
     * 
     * @type {UserSimpleResponse}
     * @memberof TextCollectionResponse
     */
    user: UserSimpleResponse;
    /**
     * 
     * @type {Array<TextEntryResponse>}
     * @memberof TextCollectionResponse
     */
    entries: Array<TextEntryResponse>;
    /**
     * 
     * @type {EWritingStyle}
     * @memberof TextCollectionResponse
     */
    writingStyle: EWritingStyle;
    /**
     * 
     * @type {Array<EWritingTargetAudience>}
     * @memberof TextCollectionResponse
     */
    targetAudiences: Array<EWritingTargetAudience>;
    /**
     * 
     * @type {EEntityState}
     * @memberof TextCollectionResponse
     */
    entityState: EEntityState;
    /**
     * 
     * @type {Array<SellingPointResponse>}
     * @memberof TextCollectionResponse
     */
    sellingPoints: Array<SellingPointResponse>;
}

/**
 * Check if a given object implements the TextCollectionResponse interface.
 */
export function instanceOfTextCollectionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "_case" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "entries" in value;
    isInstance = isInstance && "writingStyle" in value;
    isInstance = isInstance && "targetAudiences" in value;
    isInstance = isInstance && "entityState" in value;
    isInstance = isInstance && "sellingPoints" in value;

    return isInstance;
}

export function TextCollectionResponseFromJSON(json: any): TextCollectionResponse {
    return TextCollectionResponseFromJSONTyped(json, false);
}

export function TextCollectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextCollectionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        '_case': CaseResponseFromJSON(json['case']),
        'user': UserSimpleResponseFromJSON(json['user']),
        'entries': ((json['entries'] as Array<any>).map(TextEntryResponseFromJSON)),
        'writingStyle': EWritingStyleFromJSON(json['writingStyle']),
        'targetAudiences': ((json['targetAudiences'] as Array<any>).map(EWritingTargetAudienceFromJSON)),
        'entityState': EEntityStateFromJSON(json['entityState']),
        'sellingPoints': ((json['sellingPoints'] as Array<any>).map(SellingPointResponseFromJSON)),
    };
}

export function TextCollectionResponseToJSON(value?: TextCollectionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'case': CaseResponseToJSON(value._case),
        'user': UserSimpleResponseToJSON(value.user),
        'entries': ((value.entries as Array<any>).map(TextEntryResponseToJSON)),
        'writingStyle': EWritingStyleToJSON(value.writingStyle),
        'targetAudiences': ((value.targetAudiences as Array<any>).map(EWritingTargetAudienceToJSON)),
        'entityState': EEntityStateToJSON(value.entityState),
        'sellingPoints': ((value.sellingPoints as Array<any>).map(SellingPointResponseToJSON)),
    };
}

