import { ResetPasswordRequest } from 'api/core';
import { useResetPasswordRequest } from 'api/useAuthApi';
import { KeyboardEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router';

export const ForgotPasswordView = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { mutateAsync, isPending } = useResetPasswordRequest();

  const { register, handleSubmit } = useForm<ResetPasswordRequest>();

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const onSubmit = handleSubmit(async (result) => {
    try {
      await mutateAsync({
        resetPasswordRequest: result,
      });
      setHasSubmitted(true);
    } catch (error) {
      setHasSubmitted(false);
    }
  });

  return (
    <form onSubmit={onSubmit} className="space-y-4">
      <div className="form-control">
        <label className="label">
          <span className="text-base label-text">Email</span>
        </label>
        <input
          {...register('email', { required: true })}
          type="text"
          placeholder="Email"
          className="w-full input input-bordered"
          onKeyDown={handleKeyPress}
        />
      </div>

      <div>
        <button
          className="btn btn-primary btn-block"
          disabled={isPending || hasSubmitted}
        >
          Nulstil adgangskode
        </button>
      </div>

      <div className="divider"></div>

      <div className="flex justify-center">
        <NavLink to={'/login'} className="link link-hover" end>
          Tilbage til login
        </NavLink>
      </div>
    </form>
  );
};
