import {
  QueryKey,
  QueryObserverOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import { TableQueryState } from 'components/Table/useTableQueryState';
import { queryClient } from 'query-client';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  MediaSequenceAdministrationApi,
  MediaSequenceResponsePagedData,
  MediaSequenceTemplateCloneRequest,
} from './core';

export const MediaSequenceAdministrationApiKeys = {
  GET_TEMPLATES: 'media-sequnece-administration-templates',
};

const client = new MediaSequenceAdministrationApi(new CoreBaseConfiguration());

export const useGetMediaSequenceTemplates = (
  state?: TableQueryState,
  options: Omit<
    QueryObserverOptions<
      MediaSequenceResponsePagedData,
      Error,
      MediaSequenceResponsePagedData,
      MediaSequenceResponsePagedData,
      QueryKey
    >,
    'queryKey'
  > = {}
) => {
  return useQuery<MediaSequenceResponsePagedData, Error>({
    queryKey: [
      MediaSequenceAdministrationApiKeys.GET_TEMPLATES,
      state,
      options,
    ],
    queryFn: () =>
      client.mediaOrbitAdministrationTemplatesGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
    ...options,
  });
};

export const useCloneMediaSequenceTemplate = () => {
  return useMutation({
    mutationFn: ({
      mediaSequenceTemplateCloneRequest,
    }: {
      mediaSequenceTemplateCloneRequest: MediaSequenceTemplateCloneRequest;
    }) =>
      client.mediaOrbitAdministrationCloneTemplatePost({
        mediaSequenceTemplateCloneRequest,
      }),
    onSuccess: (e, variables) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceAdministrationApiKeys.GET_TEMPLATES],
      });
      toast.success(`Skabelonen er blevet kopieret til '${e.name}'`, {
        toastId:
          'cloneMediaSequenceTemplate-' +
          variables.mediaSequenceTemplateCloneRequest.targetOrganizationId +
          '-' +
          variables.mediaSequenceTemplateCloneRequest.templateId,
      });
    },
  });
};
