import { useMutation, useQuery } from '@tanstack/react-query';

import { useAuth } from 'auth/AuthProvider';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { queryClient } from 'query-client';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  EPermission,
  UserCreateRequest,
  UsersApi,
  UserUpdateRequest,
} from './core';

const UserApiKeys = {
  GET_USERS_SEARCH: 'users-search',
  GET_USERS: 'users',
  GET_USER: 'user',
};

const client = new UsersApi(new CoreBaseConfiguration());

export const useSearchUsers = ({ searchTerm }: { searchTerm?: string }) => {
  const { hasPermissions } = useAuth();

  return useQuery({
    queryKey: [UserApiKeys.GET_USERS_SEARCH, searchTerm],
    queryFn: () =>
      client.usersSearchGet({
        size: 50,
        searchTerm,
      }),
    enabled:
      searchTerm !== undefined ||
      (searchTerm != '' && hasPermissions([EPermission.UserSimpleSearch])),
  });
};

export const useGetUsers = (
  state?: TableQueryState,
  additionalOptions: {
    includeForAllOrganizations?: boolean;
  } = {
    includeForAllOrganizations: false,
  }
) => {
  return useQuery({
    queryKey: [UserApiKeys.GET_USERS, state, additionalOptions],
    queryFn: () =>
      client.usersGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
        includeForAllOrganizations:
          additionalOptions.includeForAllOrganizations,
      }),
  });
};

export const useGetUser = (
  id?: string,
  additionalOptions: {
    includeForAllOrganizations?: boolean;
  } = {
    includeForAllOrganizations: false,
  }
) => {
  return useQuery({
    queryKey: [UserApiKeys.GET_USER, id, additionalOptions],
    queryFn: () =>
      client.usersIdGet({
        id: id ?? '',
        includeForAllOrganizations:
          additionalOptions.includeForAllOrganizations,
      }),
    enabled: id !== undefined,
  });
};

export const useCreateUser = () => {
  return useMutation({
    mutationFn: ({
      userCreateRequest,
    }: {
      userCreateRequest: UserCreateRequest;
    }) => client.usersPost({ userCreateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [UserApiKeys.GET_USERS],
      });
      toast.success(`Brugeren '${e.name}' er blevet oprettet`, {
        toastId: 'createUser',
      });
    },
  });
};

export const useUpdateUser = () => {
  return useMutation({
    mutationFn: ({
      id,
      userUpdateRequest,
    }: {
      id: string;
      userUpdateRequest: UserUpdateRequest;
    }) => client.usersIdPut({ id, userUpdateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [UserApiKeys.GET_USERS],
      });
      queryClient.invalidateQueries({
        queryKey: [UserApiKeys.GET_USER, e.id],
      });
      toast.success(`Brugeren '${e.name}' er blevet opdateret`, {
        toastId: 'updateUser',
      });
    },
  });
};

export const useResendWelcomeMail = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.usersIdResendWelcomeMailPut({ id }),
    onSuccess: (e) => {
      toast.success(`Velkomstmail er gensendt til '${e.name}'.`, {
        toastId: 'welcomeUser-' + e.id,
      });
    },
  });
};

export const useDeleteUser = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) => client.usersIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [UserApiKeys.GET_USERS],
      });
      queryClient.invalidateQueries({
        queryKey: [UserApiKeys.GET_USER, e.id],
      });
      toast.success(`Brugeren '${e.name}' er blevet slettet`, {
        toastId: 'deleteUser',
      });
    },
  });
};
