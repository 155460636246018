import { useEffect, useRef, useState } from 'react';
import {
  DynamicTemplateFieldResponse,
  EAssetType,
  MediaSequenceAssetResponse,
  MetaMappingResponse,
} from 'api/core';
import { VideoPreviewWithTrim } from 'components/Video/VideoPreviewWithTrim';

interface DynamicTemplatePreviewProps {
  html: string;
  fields?: DynamicTemplateFieldResponse[];
  dynamicTemplateFieldValues?: { [key: string]: string };
  metaMappings?: MetaMappingResponse[];
  asset?: MediaSequenceAssetResponse;
  showReplayButton?: boolean;
  previewAspectRatio?: number;
  opacity?: number;
}

export const DynamicTemplatePreview = ({
  html,
  fields,
  dynamicTemplateFieldValues,
  metaMappings,
  asset,
  showReplayButton,
  previewAspectRatio,
  opacity,
}: DynamicTemplatePreviewProps) => {
  const [showPreview, setShowPreview] = useState<boolean>(true);
  const [htmlResult, setHtmlResult] = useState<string>(html);
  const iframeContainerRef = useRef<HTMLDivElement>(null);
  const [iframeContainerSize, setIframeContainerSize] = useState({
    width: 0,
    height: 0,
  });
  const [zoom, setZoom] = useState<number>(1);

  const stringifiedTemplateFieldValues = JSON.stringify(
    dynamicTemplateFieldValues
  );

  useEffect(() => {
    if (!fields || !dynamicTemplateFieldValues) {
      setHtmlResult(html);
      return;
    }

    const templateKeys = fields.map((field) => {
      let value = dynamicTemplateFieldValues[field.id];
      if (value && value.includes('{{')) {
        const keysInValue = value.match(/{{(.*?)}}/g);
        keysInValue?.forEach((key) => {
          const valueToReplace = metaMappings?.find(
            (e) => e.key === key
          )?.value;
          if (valueToReplace) {
            value = value.replaceAll(key, valueToReplace);
          }
        });
      }

      return {
        key: field.templateKey,
        value: value,
      };
    });

    // Replace templateKey with value in html
    let htmlResultTemp = html;
    templateKeys.forEach((key) => {
      htmlResultTemp = htmlResultTemp.replace(
        new RegExp(`{{${key.key}}}`, 'g'),
        key.value
      );
    });

    setHtmlResult(htmlResultTemp);
  }, [
    html,
    fields,
    dynamicTemplateFieldValues,
    stringifiedTemplateFieldValues,
    metaMappings,
  ]);

  useEffect(() => {
    const iframeContainer = iframeContainerRef.current;

    const handleResize = (entries: ResizeObserverEntry[]): void => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setIframeContainerSize({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (iframeContainer) {
      resizeObserver.observe(iframeContainer);
    }

    return () => {
      if (iframeContainer) {
        resizeObserver.unobserve(iframeContainer);
      }
    };
  }, []);

  useEffect(() => {
    if (!iframeContainerSize.width) {
      setZoom(1);
      return;
    }
    setZoom(iframeContainerSize.width / 1280);
  }, [iframeContainerSize, zoom]);

  const reRunPreview = () => {
    setShowPreview(false);
  };

  useEffect(() => {
    if (showPreview) return;
    setShowPreview(true);
  }, [showPreview]);

  const assetTrimMutation = asset?.mutations.find(
    (e) => e.trimVideo !== undefined
  )?.trimVideo;

  return (
    <div id="template-preview">
      {showReplayButton ? (
        <div>
          <button
            className="btn btn-primary"
            type="button"
            onClick={reRunPreview}
          >
            Afspil overlejring forfra
          </button>
        </div>
      ) : null}

      <div
        className="relative w-full"
        style={{ aspectRatio: previewAspectRatio }}
      >
        {asset?.asset.type === EAssetType.Video ? (
          <VideoPreviewWithTrim
            asset={asset.asset}
            trimStart={assetTrimMutation?.trimStartSeconds}
            trimEnd={assetTrimMutation?.trimEndSeconds}
            className="w-full h-full"
            style={{ zIndex: 1 }}
          />
        ) : null}

        {asset?.asset.type === EAssetType.Image ? (
          <img
            src={asset.asset.url}
            alt="Background"
            className="w-full h-full"
            style={{ aspectRatio: previewAspectRatio, zIndex: 1 }}
          />
        ) : null}

        {!asset ? (
          <img
            src="/media-orbit/dynamic-overlay-bg1.webp"
            alt="Background"
            className="w-full h-full"
            style={{ aspectRatio: previewAspectRatio, zIndex: 1 }}
          />
        ) : null}

        {showPreview ? (
          <div
            className="absolute top-0 left-0 w-full h-full"
            style={{ zIndex: 99 }}
            ref={iframeContainerRef}
          >
            <iframe
              className="border absolute top-0 left-0 w-full h-full"
              style={{ opacity: opacity || 1, zIndex: 3, zoom: zoom }}
              srcDoc={htmlResult}
              sandbox="allow-same-origin allow-scripts"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
